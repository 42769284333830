import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

import * as moment from 'moment';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-medias',
  templateUrl: './medias.component.html',
  styleUrls: ['./medias.component.scss'],
})
export class MediasComponent implements OnInit {

  tabValue = [];
  public date_update: any;

	@Input()
	  modeEdition;

	@Input()
	  get tab() {
	    return this.tabValue;
	  }

  @Output('delete') delete = new EventEmitter<any>();

  @Output() tabChange = new EventEmitter<any>();
	  set tab(val) {
	    if (val === null) { val = []; }
	    this.tabValue = val;
	  }

    tinyOptRisque = this.globalService.killObjectRef(this.globalService.tinyOpt);
    tinyOptRmq = this.globalService.killObjectRef(this.globalService.tinyOpt);

  constructor	(
  					public ref: ChangeDetectorRef,
  					public globalService: GlobalService,
  				) {

    this.tinyOptRisque.placeholder = 'Risques...'; 
    this.tinyOptRmq.placeholder = 'Remarques...'; 

   


  }
  onTabChanged() {
    console.log('onTabChanged', this.tab);
    this.tabChange.emit(this.tab);
  }
  change(){
    this.ref.detectChanges();
  }

  deleteTab() {
    this.delete.emit(this.tab);
  }

  ngOnInit() {
  	// console.log(this.constructor.name+' this.tab ::::');
  	// console.log(this.tab);
    // console.log(':::: this.tab '+this.constructor.name);
    // console.log('tab', this.tab);

    if(this.tab['date_update']){
      this.date_update = moment(this.tab['date_update'].seconds * 1000).format();
    }else{
      this.date_update = new Date();
    }
  }

  onDateChange(event){
    console.log('onDateChange:', this.date_update , this.tab['date_update']);
    this.tab['date_update'] = firebase.firestore.Timestamp.fromDate(new Date(this.date_update));
 }

}
