import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'division'
})
export class DivisionPipe implements PipeTransform {


  constructor(private globalService: GlobalService) {}
  
  transform(value: any, ...args: any[]): any {
    if(value){
      const pas = 100;
      //console.log(value, value/pas , value % pas)
      if( value % pas === 0) {
        return true;
      }else{ 
        return false;
      }
      // const date = new Date(value.seconds*1000);
      // const convert = date.getDate()+'-'+date.getMonth();
     /* if(this.globalService.stringDivision + pas <= value){
        this.globalService.stringDivision = value;
        return true;
      }else{
        // this.globalService.stringDivision = value;
        return false;
      }*/
    }else{
      return false;
    }
  }

}
