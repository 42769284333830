import { UserConnectedGuard } from './guards/user-connected.guard';
import { CanContinueGuard } from './guards/can-continue.guard';
import { HandleConnectGuard } from './guards/handle-connect.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'; 

const routes: Routes = [
  {
    path: '',
    loadChildren: './home/home.module#HomePageModule'
  },

  {
    path: 'connexion',
    loadChildren: './pages/auth/connexion/connexion.module#ConnexionPageModule'
  },
  {
    path: 'profil',
    loadChildren: './pages/profil/profil/profil.module#ProfilPageModule',
    canActivate: [ UserConnectedGuard ]
  },
  {
    path: 'profil:userID',
    loadChildren: './pages/profil/profil/profil.module#ProfilPageModule',
    canActivate: [ UserConnectedGuard ]
  },
  {
    path: 'nouvelle-inscription',
    loadChildren: './pages/auth/nouvelle-inscription/nouvelle-inscription.module#NouvelleInscriptionPageModule'
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminPageModule'
    // loadChildren: () => import('./admin/admin.module').then( m => m.AdminPageModule)
  },
  {
    path: 'cgu',
    loadChildren: './pages/cgu/cgu.module#CguPageModule'
  },
  {
    path: 'inscription-finalisation',
    loadChildren: './pages/auth/inscription-finalisation/inscription-finalisation.module#InscriptionFinalisationPageModule'
  },
  


  { path: 'recherche', loadChildren: './modals/recherche/recherche.module#RecherchePageModule' },
  { path: 'contact', loadChildren: './pages/contact/contact.module#ContactPageModule' },
  { path: 'image-modal', loadChildren: './modals/image-modal/image-modal.module#ImageModalPageModule' },
  { path: 'additivescreener', loadChildren: './pages/additivescreener/additivescreener.module#additivescreenerPageModule' },
  { path: 'additifs', loadChildren: './pages/reseaux/reseaux/reseaux.module#ReseauxPageModule' },
  { path: 'additif/:additifID', loadChildren: './pages/reseaux/details/details.module#DetailsPageModule' },
  { path: 'rgpd', loadChildren: './pages/rgpd/rgpd.module#RgpdPageModule' },
  { path: 'mentions', loadChildren: './pages/mentions/mentions.module#MentionsPageModule' },
  { path: 'dictionnaire', loadChildren: './pages/annuaire/annuaire.module#AnnuairePageModule' },
  { path: 'dictionnaire/:para1', loadChildren: './pages/annuaire/annuaire.module#AnnuairePageModule' },
  { path: 'dictionnaire/:para1/:para2', loadChildren: './pages/annuaire/annuaire.module#AnnuairePageModule' },
  { path: 'rechercher/:search', loadChildren: './pages/annuaire/annuaire.module#AnnuairePageModule' },
  { path: 'rechercher/:search/:para1', loadChildren: './pages/annuaire/annuaire.module#AnnuairePageModule' },
  { path: 'rechercher/:search/:para1/:para2', loadChildren: './pages/annuaire/annuaire.module#AnnuairePageModule' },
  { path: 'methodologie', loadChildren: './pages/methodologie/methodologie.module#MethodologiePageModule' },
  { path: 'pourquoi', loadChildren: './pages/pourquoi/pourquoi.module#PourquoiPageModule' },
  { path: 'checkboxes', loadChildren: './modals/checkboxes/checkboxes.module#CheckboxesPageModule' },
  { path: 'merci', loadChildren: './pages/merci/merci.module#MerciPageModule' },
  { path: 'handle-connect', children: [], canActivate: [ HandleConnectGuard ] },
  { path: 'update-infos', loadChildren: './admin/update-infos/update-infos.module#UpdateInfosPageModule' },
  { path: 'faq', loadChildren: './pages/faq/faq.module#FaqPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
