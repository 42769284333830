import { ModalController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { mediaType } from '../../components/media/media.component';

@Component({
  selector: 'app-media-edition',
  templateUrl: './media-edition.page.html',
  styleUrls: ['./media-edition.page.scss'],
})
export class MediaEditionPage implements OnInit {

  @Input() element: mediaType;
  @Input() type: 'image' | 'fichier';

  constructor(
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
    //console.log('popover', this.element);
  }

}