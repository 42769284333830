import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-reglementation',
  templateUrl: './reglementation.component.html',
  styleUrls: ['./reglementation.component.scss'],
})
export class ReglementationComponent implements OnInit {

  tabValue: {
    NOM?: string,
    STATUS?: string,
    UTILISATIONS?: string,
    PLUSINFOS?: string,
    CODE?: string,
    LINK?: string,
    field9?: string,
  } = null;

  @Input()
    modeEdition;

  @Input()
    get tab() {
      return this.tabValue;
    }

  @Output('delete') delete = new EventEmitter<any>();

  @Output() tabChange = new EventEmitter<any>();
    set tab(val:any) {
      if (val === null) { val = []; }
      this.tabValue = val;
    }

    tinyOptSrc = this.globalService.tinyOpt;

  constructor  (
            public ref: ChangeDetectorRef,
            public globalService: GlobalService,
          ) {

    this.tinyOptSrc.placeholder = 'Source...'; 

  }
  onTabChanged() {
    console.log('onTabChanged', this.tab);
    this.tabChange.emit(this.tab);
  }
  change(){
    this.ref.detectChanges();
  }


  deleteTab() {
    this.delete.emit(this.tab);
  }

  ngOnInit() {
    // console.log(this.constructor.name+' this.tab ::::');
    // console.log(this.tab);
    // console.log(':::: this.tab '+this.constructor.name);
  }

}
