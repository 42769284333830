import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CarteRecherchePage } from '../../modals/carte-recherche/carte-recherche.page';
import { ModalController } from '@ionic/angular';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-l-adresse',
  templateUrl: './l-adresse.component.html',
  styleUrls: ['./l-adresse.component.scss'],
})
export class LAdresseComponent implements OnInit {


  @Input() bt = 'Ajouter une adresse';
  @Input() objAdresse: any = {};
  @Output() adresseChanged = new EventEmitter<any>();


  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  onAdresseChanged() {
    //console.log('onAdresseChanged()');
    this.adresseChanged.emit( this.objAdresse );
  }
  suppression() {
    this.objAdresse = {
      adresse: null,
      cp: null,
      commune: null,
      pays: null,
      coord: null
    };
    this.onAdresseChanged();
  }

  async modifyAdress() {
    const modal = await this.modalCtrl.create({
      component: CarteRecherchePage
    });
    modal.onDidDismiss().then(data => {
      if ( data.data !== undefined) {

        let cp = null;
        let commune = null;
        let pays = null;
        const adresse = data.data.place_name;
        const latitude = data.data.geometry.coordinates[1];
        const longitude = data.data.geometry.coordinates[0];
        const coord = new firebase.firestore.GeoPoint(latitude, longitude);

        data.data.context.forEach(element => {
          const temp = element.id.split('.');
          if (temp[0] === 'postcode') { cp = element.text; }
          if (temp[0] === 'place')    { commune = element.text; }
          if (temp[0] === 'country')  {
            pays = element.short_code.toUpperCase();
           }
        });

        this.objAdresse.cp = cp;
        this.objAdresse.commune = commune;
        this.objAdresse.adresse = adresse;
        this.objAdresse.coord = coord;
        this.objAdresse.pays = pays;
        this.onAdresseChanged();
      }
    });
    await modal.present();
  }

}
