import { ModalController, NavParams } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkboxes',
  templateUrl: './checkboxes.page.html',
  styleUrls: ['./checkboxes.page.scss'],
})
export class CheckboxesPage implements OnInit {

  CompleteList: any[] = [];
  seeAll = true;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParams,
  ) {
  }

  filterResult(event) {
    const items = Array.from(document.getElementById((this.seeAll) ? 'itemList' : 'selectedItemList').children);
    const query = event.target.value.toLowerCase();
    items.forEach( (item: any) => {
      item.style.display = (item.textContent.toLowerCase().indexOf(query) > -1) ? 'block' : 'none';
    });
  }

  async validate() {
    let toReturn = this.CompleteList.filter(v => {
      return v.isChecked == true;
    });
    let ToReturnValue = [];
    toReturn.forEach(element => {
      ToReturnValue.push(element.name);
    });
    console.log(ToReturnValue);
    this.CompleteList = [];
    await this.modalCtrl.dismiss(ToReturnValue);
  }

  ionViewWillEnter(){
    this.CompleteList = [...this.navParams.get('complete')];
    let CheckedList: any[] = this.navParams.get('checked');
    console.log(this.CompleteList, CheckedList);

    for (let i = 0; i != this.CompleteList.length; i++) {
      let isChecked = false;

      if (CheckedList.indexOf(this.CompleteList[i]) != -1) {
        isChecked = true;
      }

      if (typeof this.CompleteList[i] == 'string')
        this.CompleteList[i] = { name: this.CompleteList[i], isChecked: isChecked };
      else
        this.CompleteList[i].isChecked = isChecked;
    }
  }

  ngOnInit() {
  }

}
