import { ResizePage } from './../../modals/resize/resize.page';
import { Camera, CameraOptions } from '@ionic-native/camera';
import { AlertController, PopoverController, ModalController, Platform } from '@ionic/angular';
import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { MediaEditionPage } from '../../modals/media-edition/media-edition.page';

export type mediaType = {
  name: string,
  path: string,
  url: string,
  description?: string,
  alt?: string,
  type?: any,
}

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {

  @Input() type: 'image' | 'file';
  @Input() path: string;
  
  tabValue: mediaType[];
  @Input()
  get tab() {
    return this.tabValue;
  }
  @Output() tabChange = new EventEmitter<mediaType[]>();
  set tab(val) {
    this.tabValue = val;
    this.tabChange.emit(this.tabValue);
  }

  loading = false;

  constructor(
    public alertCtrl: AlertController,
    public db: AngularFirestore,
    public popoverCtrl: PopoverController,
    public modalCtrl: ModalController,
    public platform: Platform,
  ) { }

  ngOnInit() {
    //console.log('media', this);
  }

  onRenderItems(event) {
    //console.log(`Moving item from ${event.detail.from} to ${event.detail.to}`);
    let temp = [...this.tab];
    let draggedItem = temp.splice(event.detail.from,1)[0];
    temp.splice(event.detail.to,0,draggedItem);
    event.detail.complete();
    this.tab = temp;
  }

  delete(element: mediaType) {
    firebase.storage().ref().child(element.path).delete();
    let temp = [...this.tab];
    temp.splice(temp.indexOf(element), 1);
    this.tab = temp;
  }

  async openElementEditing(element: mediaType, type: 'image' | 'fichier') {
    document.getElementById(element.path)['close']();
    const modal = await this.modalCtrl.create({
      component: MediaEditionPage,
      cssClass: 'transparent-modal',
      mode: 'ios',
      componentProps: {
        'element': element,
        'type': type,
      },

    });
    modal.onDidDismiss().then(newValue => {
      if (newValue.data) {
        let temp = [...this.tab];
        temp[temp.indexOf(element)] = newValue.data as mediaType;
        this.tab = temp;
      }
    });
    await modal.present();
  }

  async photoSource() {
    const alert = await this.alertCtrl.create({
      header: "Ajout de photo",
      message: "Choisir la source",
      mode: 'ios',
      cssClass: 'alertPhoto',
      buttons: [
        {
          text: "Galerie",
          cssClass: 'gallery',
          handler: () => this.addPhoto(1)
        },
        {
          text: "Appareil",
          cssClass: 'camera',
          handler: () => this.addPhoto(0)
        },
        {
          text: "Annuler",
          role: 'close',
        },
      ]
    });
    await alert.present();
  }

  async resizeImg(imageData: string) {
    return await this.modalCtrl.create({
      component: ResizePage,
      componentProps: {
        'image': 'data:image/jpeg;base64,' + imageData,
        'ratio': NaN,
        'width':1024,
        'height':1024,
      }
    });
  }

  addPhoto(source: number) {
    let options: CameraOptions = {
      quality: 90,
      destinationType: Camera.DestinationType.DATA_URL,
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE,
      sourceType : (source == 1) ? Camera.PictureSourceType.PHOTOLIBRARY : Camera.PictureSourceType.CAMERA,
      cameraDirection : Camera.Direction.BACK,
      correctOrientation: true,
      targetHeight: 1024,
    }
    Camera.getPicture(options).then(async imageData => {


      await this.resizeImg(imageData).then(async modal => {
        modal.onDidDismiss().then(async data => {
          let base64 = data.data.split(',')[1];



          let img = new Image();
          img.src = 'data:image/jpeg;base64,' + base64;

          img.onload = async () => {

            let ratio = 1024 / img.naturalHeight;
            let canvas = document.createElement('canvas');
            canvas.width = img.naturalWidth * ratio;
            canvas.height = img.naturalHeight * ratio;
            let ctx = canvas.getContext('2d');
            //let ratio = 256 / img.naturalWidth;
            // console.log('ratio', ratio);
            ctx.drawImage(img, 0, 0, img.naturalWidth * ratio, img.naturalHeight * ratio);

            
            // console.log(canvas.height);
            base64 = canvas.toDataURL();
        
            this.loading = true;
            let array = [];
            let binary = atob(base64.split(',')[1]);
            for (let i = 0; i < binary.length; i++) {
              array.push(binary.charCodeAt(i));
            }
          
            let photoID = Date.now();
            let temp = [...this.tab];
            await firebase.storage().ref()
            .child(`${this.path}/${photoID}.jpeg`)
            .put(new Blob([new Uint8Array(array)], { type: 'image/jpeg' }))
            .then(async snapshot => {
              let photo: mediaType = { name: null, path: null, url: null };
            
              await snapshot.ref.getMetadata().then(metadata => {
                photo.name = photoID.toString() + '.jpg';
                photo.path = metadata['fullPath'];
              });
              await snapshot.ref.getDownloadURL().then(url => {
                photo.url = url;
              });
              await temp.push(photo);
              this.tab = temp;
              this.loading = false;
            });
          }
        });
        await modal.present();
      });
    });
  }

  async addPhotoFromPC() {
    let fileData = document.getElementById('thephoto')['files'][0];

    //console.log('file', fileData);

    if (fileData['type'] == 'image/jpeg' || fileData['type'] == 'image/png') {
      let reader = new FileReader;

      reader.onload = async () => {
        let base64 = reader.result.toString().split(',')[1];


        //RESIZE
        await this.resizeImg(base64).then(async modal => {
          modal.onDidDismiss().then(async data => {
            base64 = data.data.split(',')[1];

            let img = new Image();
            img.src = 'data:image/jpeg;base64,' + base64;

            img.onload = async () => {

              let ratio = 1024 / img.naturalHeight;
              let canvas = document.createElement('canvas');
              canvas.width = img.naturalWidth * ratio;
              canvas.height = img.naturalHeight * ratio;
              let ctx = canvas.getContext('2d');
              //let ratio = 256 / img.naturalWidth;
              // console.log('ratio', ratio);
              ctx.drawImage(img, 0, 0, img.naturalWidth * ratio, img.naturalHeight * ratio);


              // console.log(canvas.height);
              base64 = canvas.toDataURL();
            
              this.loading = true;
              let array = [];
              let binary = atob(base64.split(',')[1]);
              for (let i = 0; i < binary.length; i++) {
                array.push(binary.charCodeAt(i));
              }
            
              let photoID = Date.now();
              let temp = [...this.tab];
              await firebase.storage().ref()
              .child(`${this.path}/${photoID}.jpeg`)
              .put(new Blob([new Uint8Array(array)], { type: 'image/jpeg' }))
              .then(async snapshot => {
                let photo: mediaType = { name: null, path: null, url: null };
              
                await snapshot.ref.getMetadata().then(metadata => {
                  photo.name = photoID.toString() + '.jpg';
                  photo.path = metadata['fullPath'];
                });
                await snapshot.ref.getDownloadURL().then(url => {
                  photo.url = url;
                });
                await temp.push(photo);
                this.tab = temp;
                this.loading = false;
              });
            }
          });
          await modal.present();
        });
      }
      reader.readAsDataURL(fileData);
    }
  }

  async addDocument() {
    let fileData = document.getElementById('thefile')['files'][0];

    //console.log('addDocument', fileData);

    this.loading = true;
    let reader = new FileReader;
    let base64 = null;
    await reader.readAsDataURL(fileData);
    await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result.toString().split(',')[1]);
    }).then(base64 => base64 = base64);
    let array = [];
    let binary = atob(base64);
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }

    let fichierID = this.db.createId();
    let temp = [...this.tab];
    await firebase.storage().ref()
    .child(`${this.path}/${fichierID}.${fileData['name']}.${fileData['type'].split('/')[1]}`)
    .put(new Blob([new Uint8Array(array)], { type: fileData['type'] }))
    .then(async snapshot => {
      let fichier: mediaType = { name: null, path: null, url: null, type: null };

      await snapshot.ref.getMetadata().then(metadata => {
        fichier.name = metadata['name'].split('.')[1];
        fichier.type = (metadata['name'].split('.')[2] == 'undefined') ? null : metadata['name'].split('.')[2];
        fichier.path = metadata['fullPath'];
      });
      await snapshot.ref.getDownloadURL().then(url => {
        fichier.url = url;
      });

      await temp.push(fichier);
      this.tab = temp;
      this.loading = false;
    });
  }

}