export const TAG_COLORS = {
    // ionic colors
    'primary': null,
    'secondary': null,
    'tertiary': null,
    'success': null,
    'warning': null,
    'danger': null,
    'dark': null,
    'medium': null,
    'light': null,

    // custom colors
    'warn': 'green',
    'gray': '#767676',
    'purple': '#7e60ff'
};
