import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpModule } from "@angular/http";
import { HttpClientModule } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { ImageResizer } from "@ionic-native/image-resizer/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { LaunchNavigator } from "@ionic-native/launch-navigator/ngx";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { PhotoViewer } from "@ionic-native/photo-viewer/ngx";

import { AuthentificationService } from "./services/authentification.service";
import { UserService } from "./services/user.service";
import { ElasticsearchService } from './services/elasticsearch.service';

import { environment } from "../environments/environment";

import { AngularFireModule } from "@angular/fire";
import {
  AngularFireDatabaseModule,
  AngularFireDatabase
} from "@angular/fire/database";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuthModule } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CguPageModule } from "./pages/cgu/cgu.module";
import { ServiceWorkerModule } from "@angular/service-worker";

// modals /////
import { RechercheDialPageModule } from "./modals/recherche-dial/recherche-dial.module";
import { TextareaPageModule } from "./modals/textarea/textarea.module";
import { ImageModalPageModule } from "./modals/image-modal/image-modal.module";
import { RecherchePageModule } from "./modals/recherche/recherche.module";
import { ResizePageModule } from "./modals/resize/resize.module";
import { MediaEditionPageModule } from "./modals/media-edition/media-edition.module";
import { CheckboxesPageModule } from "./modals/checkboxes/checkboxes.module";
import { EditorTemplatesPageModule } from "./modals/editor-templates/editor-templates.module";
import { UpdateInfosPageModule } from "./admin/update-infos/update-infos.module";

// composannts ///////
import { CouleursComponent } from './components/couleurs/couleurs.component';
import { CouleursmediaComponent } from './components/couleursmedia/couleursmedia.component';


//import { IonAffixModule } from "ion-affix/dist/ion-affix.module";

// pipes
// import { PipesModule } from './pipes/pipes.module';
// import { PaysPipe } from './pipes/pays.pipe';
// import { SecteursPipe } from './pipes/secteurs.pipe';

firebase.initializeApp(environment.firebase);
@NgModule({
  declarations: [AppComponent,CouleursComponent,CouleursmediaComponent],
  entryComponents: [CouleursComponent,CouleursmediaComponent],
  imports: [
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    RecherchePageModule,
    ImageModalPageModule,
    RechercheDialPageModule,
    TextareaPageModule,
    ResizePageModule,
    HttpClientModule,
    EditorTemplatesPageModule,
    UpdateInfosPageModule,
    CguPageModule,
    MediaEditionPageModule,
    CheckboxesPageModule,
    HttpModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    //IonAffixModule
    // PipesModule,
  ],
  providers: [
    OneSignal,
    AngularFirestore,
    AndroidPermissions,
    Diagnostic,
    SocialSharing,
    ImageResizer,
    Geolocation,
    StatusBar,
    SplashScreen,
    AuthentificationService,
    UserService,
    ElasticsearchService,
    AngularFireDatabase,
    LaunchNavigator,
    PhotoViewer,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
