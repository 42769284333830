import { Component, OnInit, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { ModelService } from 'src/app/services/model.service';

@Component({
  selector: 'app-science-info',
  templateUrl: './science-info.component.html',
  styleUrls: ['./science-info.component.scss'],
})
export class ScienceInfoComponent implements OnInit {

  tabValue:any;

  @Input()
    modeEdition;

  @Input()
    get tab() {
      return this.tabValue;
    }

  @Output('delete') delete = new EventEmitter<any>();

  @Output() tabChange = new EventEmitter<any>();
    set tab(val) {
      if (val === null) { val = []; }
      this.tabValue = val;
    }

    tinyOpt = this.globalService.tinyOpt;
    tinyOptSrc = this.globalService.tinyOpt;



  constructor  (
            public ref: ChangeDetectorRef,
            public globalService: GlobalService,
            public modelService: ModelService,
          ) {


    this.tinyOptSrc.placeholder = 'Source...'; 



  }
  onTabChanged() {
    console.log('onTabChanged', this.tab);
    this.tabChange.emit(this.tab);
  }
  change(){
    this.ref.detectChanges();
  }
  deleteTab() {
    this.delete.emit(this.tab);
  }
  deleteSource(index) {
    this.tab.SOURCES.splice(index, 1);
  }
  addScienceInfoSource() {
    if(this.tab.SOURCES == undefined) {
      this.tab.SOURCES = [];
    }
    this.tab.SOURCES.push("");
    // this.modelService.additifSCIENCEPrototype.INFOS.SOURCES
  }

  ngOnInit() {
    // console.log(this.constructor.name+' this.tab ::::');
    // console.log(this.tab);
    // console.log(':::: this.tab '+this.constructor.name);
  }

  trackByFn(index: any, item: any) {
     return index;
  }


}
