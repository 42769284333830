import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PaysPipe } from './pays.pipe';
import { DepuisPipe } from './depuis.pipe';
import { CivilityPipe } from './civility.pipe';
import { InfosPipe } from './infos.pipe';
import { ListesPipe } from './listes.pipe';
import { DivisionPipe } from './division.pipe';
import { ClientPipe } from './client.pipe';
import { ScienceCodePipe } from './sciencecode.pipe';
import { BooleancorrespondancePipe } from './booleancorrespondance.pipe';

@NgModule({
  declarations: [
       PaysPipe,
       DepuisPipe,
       CivilityPipe,
       InfosPipe,
       ListesPipe,
       DivisionPipe,
       ClientPipe,
       ScienceCodePipe,
       BooleancorrespondancePipe
    ],
  imports: [IonicModule],
  exports: [
     PaysPipe,
     DepuisPipe,
     CivilityPipe,
     InfosPipe,
     ListesPipe,
     DivisionPipe,
     ClientPipe,
     ScienceCodePipe,
     BooleancorrespondancePipe
  ]
})
export class PipesModule {}
