import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'civility'
})
export class CivilityPipe implements PipeTransform {

  transform(value: 'M' | 'F' | 'A', args: 'L' | 'B'): any {
    switch(value) {
      case 'M': return (args == 'B') ? 'Monsieur' : 'Mr';
      case 'F': return (args == 'B') ? 'Madame' : 'Mme';
      case 'A': return (args == 'B') ? 'Autre' : '';
    }
  }
}
