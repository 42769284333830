import { Pipe, PipeTransform } from '@angular/core';
import * as firebase from 'firebase/app';

@Pipe({
  name: 'depuis'
})
export class DepuisPipe implements PipeTransform {

  private LE: string = 'le ';
  private DANS: string = 'dans ';
  private ILYA: string = 'il y a ';
  private MAINTENANT: string = 'maintenant';
  private J: string = 'j';
  private H: string = 'h';
  private MIN: string = 'min';

  constructor(
  ) {
    //translate.get('PIPES.LE').subscribe(value => { this.LE = value; });
    //translate.get('PIPES.DANS').subscribe(value => { this.DANS = value; });
    //translate.get('PIPES.ILYA').subscribe(value => { this.ILYA = value; });
    //translate.get('PIPES.MAINTENANT').subscribe(value => { this.MAINTENANT = value; });
    //translate.get('PIPES.J').subscribe(value => { this.J = value; });
    //translate.get('PIPES.H').subscribe(value => { this.H = value; });
    //translate.get('PIPES.MIN').subscribe(value => { this.MIN = value; });
  }
  transform(date: firebase.firestore.Timestamp, ...args) {
    ////console.log("DATE : " ,date) ; 
    if (date != undefined) {


      let msecPerMinute = 1000 * 60;
      let msecPerHour = msecPerMinute * 60;
      let msecPerDay = msecPerHour * 24;


      let maintenant = new Date(Date.now());

      let interval = maintenant.getTime() - date.seconds * 1000;

      let futur: boolean = false;
      if (interval <= 0) { // avant
        futur = true;
        interval = date.seconds * 1000 - maintenant.getTime();
      }

      let days = Math.floor(interval / msecPerDay);
      interval = interval - (days * msecPerDay);

      let hours = Math.floor(interval / msecPerHour);
      interval = interval - (hours * msecPerHour);

      let minutes = Math.floor(interval / msecPerMinute);
      interval = interval - (minutes * msecPerMinute);

      if (days > 0) {
        if (futur) {
          return this.DANS + days + " " + this.J + " ";
        } else {
          if (days > 31) {
            let ladate = new Date(date.seconds * 1000);
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return ladate.toLocaleDateString('fr-FR', options);
          } else {
            return this.ILYA + days + " " + this.J + " ";
          }

        }

      } else {
        if (hours > 0) {
          if (futur) {
            return this.DANS + hours + " " + this.H + " ";
          } else {
            return this.ILYA + hours + " " + this.H + " ";
          }
        } else {
          if (minutes == 0) {
            return this.MAINTENANT;
          } else {
            if (futur) {
              return this.DANS + minutes + " " + this.MIN;
            } else {
              return this.ILYA + minutes + " " + this.MIN;
            }

          }
        }
      }
    }
  }

}
