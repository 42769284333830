import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, NavParams } from '@ionic/angular';
import { Countries } from '../../data';

@Component({
  selector: 'app-recherche-dial',
  templateUrl: './recherche-dial.page.html',
  styleUrls: ['./recherche-dial.page.scss'],
})
export class RechercheDialPage implements OnInit {

   Countries = [];

  constructor(
    public modalCtrl: ModalController,
    public alertCtrl: AlertController,
    public navParams: NavParams
  ) {
    this.Countries = Countries;
    const checked = navParams.get('countryChecked');
    //console.log('checked : ', checked );
    /*

    for (const country of Countries) {
      let isChecked = false;

      if ( country.call === checked ) { isChecked = true; }

      this.Countries.push({
        value: country,
        isChecked,
      });
    }*/
  }
  ngOnInit() {
  }

  async dismiss() {
    await this.modalCtrl.dismiss();
  }


  filterResult(event) {
    //console.log('filterResult', event);
    const items = Array.from(document.getElementById('itemList').children);
    const query = event.target.value.toLowerCase();

    //console.log(query , items);

    items.forEach( (item: any) => {
      item.style.display = (item.textContent.toLowerCase().indexOf(query) > -1) ? 'block' : 'none';
    });
  }

  validate(item) {
    //console.log('validate()',  item);
    this.modalCtrl.dismiss(item);
  }


  isCountriesChecked() {
    const filtered = this.Countries.filter((country) => {
      return country.isChecked;
    });
    return filtered;
  }

}
