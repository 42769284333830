import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as Cropper from 'cropperjs/dist/cropper';

@Component({
  selector: 'app-resize',
  templateUrl: './resize.page.html',
  styleUrls: ['./resize.page.scss'],
})
export class ResizePage implements OnInit {

  @Input() image: any;
  cropper: any;
  @Input() ratio: number;
  @Input() width: number = 512;
  @Input() height: number = 512;

  constructor(
    public modalCtrl: ModalController,
  ) {
  }

  ionViewWillEnter() {
    //console.log('resize_modal_enter', this.image);
    const imageID = document.getElementById('imageID');
    this.cropper = new Cropper(imageID, {
      aspectRatio: this.ratio,
      dragMode: 'none',
      checkCrossOrigin: false,
      cropBoxMovable: true,
      viewMode: 2,
      background: false,
      width : this.width,
      height : this.height,
    });
    // this.defaultTaille();
  }

  /*defaultTaille(){
    const data = this.cropper.getCropBoxData();
    this.cropper.setCropBoxData({
      left : data.left,
      top : data.top,
      width : this.width,
      height : this.height,
    });
  }*/

  cropImage() {
    let canvas: HTMLCanvasElement = this.cropper.getCroppedCanvas();

    //console.log(canvas.toDataURL());
    this.modalCtrl.dismiss(canvas.toDataURL());
  }

  ngOnInit() {
  }

}
