import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { AuthentificationService } from './authentification.service';
import * as firebase from 'firebase/app';

export type userModel = {
  userID?: string,
  prenom?: string,
  nom?: string,
  email?: string,
  civil?: 'M' | 'F' | 'A',
  telephone?: string,
  photo?: string,
  admin?: boolean,
  statut?: boolean,
  enabled?: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public user: userModel = {};

  constructor(
    public auth: AuthentificationService,
    public db: AngularFirestore,
  ) {
    auth.authSubject.subscribe(data => {
      console.log('UserService=>authSubject', data);
      if (data) {
        this.user = data;
        this.auth.user = data;
      }
      else{
        this.user = {};
        this.auth.user = {};
      }
    });
  }

  setUserInDatabase(user, userID: string) {
    const data = Object.assign(user, {
      userID: userID,
      prenom: user.prenom,
      nom: user.nom,
      email: user.email,
      civil: user.civil,
      telephone: null,
      photo: null,
    });
    this.db.collection('users').doc(userID).set(data);
  }

  async updateUser(element: string, data: {}) {
    if (element === 'email') {
      await firebase.auth().currentUser.updateEmail(data['email']);
    }
    await this.db.collection('users').doc(this.user.userID).update(data);
  }
}