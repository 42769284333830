import { Pipe, PipeTransform } from '@angular/core';
// import { Infos } from '../data';
import { GlobalService } from '../services/global.service';

@Pipe({
  name: 'infos'
})
export class InfosPipe implements PipeTransform {

  constructor(
    public globalService : GlobalService,
  ){}

  transform(value: any, type:string = 'name') : any {;
    let valeur :any;
    if( type === 'name' ){
      valeur = value;
      for (const inf of this.globalService.datajs.Infos) {
        if ( inf.code === value ) { valeur = inf.name; }
      }
    }
    if( type === 'couleurs' ){
      valeur = [];
      for (const inf of this.globalService.datajs.Infos) {
        if ( inf.code === value ) { valeur = inf.couleurs; }
      }
    }
    return valeur;
  }
}
