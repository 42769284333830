import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-link-preview',
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.scss'],
})
export class LinkPreviewComponent implements OnInit {

  @Input() url: string;
  data: {
    title: string,
    description: string,
    image: string,
    url: string,
  } = null;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
  ) {
  }

  ionViewWillEnter() {
  }

  goTo() {
    document.location.href = this.url;
  }

  ngOnInit() {
    let api = environment.linkPreviewURL + this.url;
    let xmlhtp = new XMLHttpRequest();

    xmlhtp.onreadystatechange = res => {
      let dataAsJSON = JSON.parse(res.target['response']);
      this.data = dataAsJSON;
    }
    xmlhtp.open('GET', api, false);
    xmlhtp.send();
  }

}
