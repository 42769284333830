import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-couleurs',
  templateUrl: './couleurs.component.html',
  styleUrls: ['./couleurs.component.scss'],
})
export class CouleursComponent implements OnInit {

  public category  ;

  constructor(
    public popoverCtrl: PopoverController,
    public globalService: GlobalService,
    public userService: UserService,
    public navParams : NavParams
  ) { 
    this.category = this.navParams.get('category');
    console.log('this.category:', this.category);


  }

  ngOnInit() {}

}
