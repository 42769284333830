import { NavController, ToastController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { AuthentificationService } from 'src/app/services/authentification.service';

@Component({
  selector: 'app-item-thumbnail',
  templateUrl: './item-thumbnail.component.html',
  styleUrls: ['./item-thumbnail.component.scss'],
})
export class ItemThumbnailComponent implements OnInit {

  @Input() additif: any = null;
  @Input() height: number;
  @Input() bts: boolean = false;
  @Input() annuaire: boolean = false;

  constructor(
    public navCtrl: NavController,
    public toastCtrl: ToastController,
    public globalService:GlobalService,
    public authService: AuthentificationService,
  ) {
  }

  async seeAdditif() {
    if(this.additif.step){
      if (this.additif.step == 6) {
        this.navCtrl.navigateForward('/additif/' + this.additif.additifID);
      } else {
        const toast = await this.toastCtrl.create({
          mode: 'ios',
          position: 'middle',
          color: 'dark',
          duration: 5000,
          header: 'Vous devez d\'abord finir la création de votre réseau !',
          closeButtonText: 'D\'accord',
          showCloseButton: true,
        });
        await toast.present();
      }
    }
  }

  ngOnInit() { }

}