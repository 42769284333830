import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.page.html',
  styleUrls: ['./recherche.page.scss'],
})
export class RecherchePage implements OnInit {

      // variables du composant
      public modal = true;
      public titre = 'Rechercher un projet';
      public couleurdeFond = 'fond';

  constructor(
    public modalCtrl:ModalController
  ) { }
  

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
