import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-update-infos',
  templateUrl: './update-infos.page.html',
  styleUrls: ['./update-infos.page.scss'],
})
export class UpdateInfosPage implements OnInit {

  public item :any;
  public tinyOptSourceReglementation = this.globalService.tinyOpt;

  constructor(
    public modalController : ModalController,
    public navParams: NavParams,
    public globalService: GlobalService,
  ) { 
    this.item =  this.navParams.get('item');
    this.tinyOptSourceReglementation.placeholder = 'Source...';
  }

  ngOnInit() {
  }
  save(){
    this.modalController.dismiss( this.item ,'save');
  }
  cancel(){
    this.modalController.dismiss( this.item ,'cancel');
  }
  trackByFn(index: any, item: any) {
    return index;
  }
  deleteItem(array, item) {
    const index = array.indexOf(item);
    console.log(index);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
  addItem(array, item) {
    if(array == undefined) array = [];
      array.push(item);
  }
}
