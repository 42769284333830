import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { NavController, PopoverController } from '@ionic/angular';
import { Router } from '@angular/router';

import { ElasticsearchService } from 'src/app/services/elasticsearch.service';
import { AuthentificationService } from 'src/app/services/authentification.service';

import { CouleursComponent } from '../couleurs/couleurs.component';
import { CouleursmediaComponent } from '../couleursmedia/couleursmedia.component';

@Component({
  selector: 'app-rechercher',
  templateUrl: './rechercher.component.html',
  styleUrls: ['./rechercher.component.scss'],
})
export class RechercherComponent implements OnInit {


  @Input() modefooter: boolean = false;
  @Output() scrollhaut = new EventEmitter<any>();
  query: any[] = [];
  actif:boolean = false;
  public largeur;
  


  constructor(
    public globalService:GlobalService,
    public authService : AuthentificationService,
    public navControler: NavController,
    public router: Router,
    public elastic: ElasticsearchService,
    public popoverController: PopoverController,
  ) { 
   
}
retour(str){
  /*if(str=='objectif'){
    this.globalService.objectif='tous';
  }*/
  if(str=='categorie'){
    this.globalService.categorie='toutes';
  }
  this.scrollhaut.emit();
}

async clickCouleursMedias(ev: any, media:any = null) {
  
  const popover = await this.popoverController.create({
    component: CouleursmediaComponent,
    cssClass: 'couleursmedias-popover',
    event: ev,
    translucent: true
  });
  console.log("clickCouleursMedias",media);
  popover.onDidDismiss().then((event) => {
    console.log('dissmiss', event);
    console.log('elastic:: ',this.globalService.elastic);  // <<<----------- ICI
    // this.globalService.elastic.diag_MEDIA = 'Toutes';
    this.elastic.rechercher(1000,0,true);
  });
  return await popover.present();


  /*
  if(this.globalService.elastic.category == category.noeud){
    this.globalService.elastic.category = 'Toutes';
    this.globalService.elastic.diag = 'Toutes';
    this.elastic.rechercher(9,0,true);
  }else{
    this.globalService.elastic.category = category.noeud;
    popover.onDidDismiss().then((event) => {
      console.log('dissmiss', event);
      this.elastic.rechercher(9,0,true);
    });
    return await popover.present();
  }*/


}

async clickAnnule(){
  this.elastic.rechercher(9,0,true);
}

async clickSource(ev: any,category) {
  console.log('___________________________')
  console.log('elastic:: ',this.globalService.elastic);
  
  const popover = await this.popoverController.create({
    component: CouleursComponent,
    cssClass: 'my-custom-class',
    componentProps: {category},
    event: ev,
    translucent: true,
  });
  console.log("category",category,this.globalService.elastic.category, this.globalService.elastic['diag_'+category.noeud]);
  if(this.globalService.elastic.category == category.noeud){
    this.globalService.elastic.category = 'Toutes';
    this.globalService.elastic['diag_'+category.noeud] = 'Toutes';
    this.elastic.rechercher(1000,0,true);
  }else{
    this.globalService.elastic.category = category.noeud;
    popover.onDidDismiss().then((event) => {
      console.log('dissmiss', event, category);
      console.log('elastic:: ',this.globalService.elastic);  // <<<----------- ICI
      // if(this.globalService.elastic.diag_MEDIA != 'Toutes') {
      //   this.globalService.elastic.media.code = null;
      // }
      this.elastic.rechercher(1000,0,true);
    });
    return await popover.present();
  }

  console.log('___________________________')
}


rechercheOk() {

  let retourne = true;

  if(this.globalService.elastic.category != 'Toutes' && this.globalService.elastic.diag_MEDIA == 'Toutes') {
    retourne =  false;
  }

  //console.log('retourne :', retourne);
  //console.log(retourne);

  return retourne;
}


  ngOnInit() {
    console.log('RELOAD');
    console.log(this.router.url);
    this.elastic.rechercher();
  }
  searchBlur(){
    console.log('searchBlur');
    setTimeout(() => {
      this.actif = false;
    }, 300);
   
  }
  

  changeView() {

    /*console.log('changeView('+this.globalService.secteur +'/'+this.globalService.objectif +')');
    let url = '';
    if (this.globalService.secteur != 'tous') {
      url += '/'+this.globalService.secteur;
    }
    if (this.globalService.objectif != 'tous') {
      url += '/'+this.globalService.objectif;
    }
    if (this.globalService.search === '') {
      url = '/dictionnaire'+url;
    } else {
      if (url ==='') {
        url = '/rechercher/'+this.globalService.search;
      } else {
        url = '/rechercher/'+this.globalService.search+url;
      }
    }
    this.elastic.rechercher();
    this.navControler.navigateForward(url);
    */
  }
  
  additifSearch(event) {
    console.log('additifSearch()');
    let list = document.getElementById('additif-list');
    if (list) {
      let target = event.path[0];
      console.log(target);
      if (target) {
        list.style.width = target.offsetWidth + 'px';
        list.style.top = (target.offsetTop + target.offsetHeight + 30) + 'px';
        list.style.left = (target.offsetParent.offsetLeft + target.offsetLeft) + 'px';
        list.classList.add('actived')
      }
    }
  }

  ionViewWillEnter() {
    this.elastic.rechercher();
  }

}
