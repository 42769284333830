import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.scss'],
})
export class RecapComponent implements OnInit {
  type: 'Additifs' | 'Réglementations' | 'Scores' | 'RéglementationsUE' | 'Listes' | 'Médias';
  changements: {
    additif: string,
    data: object,
    action: 'edition' | 'creation'
  }[];

  constructor(
    public modalCtrl: ModalController,
  ) { }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
    console.log(this.changements, this.type.toLowerCase());
  }

}
