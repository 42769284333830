import { Component, OnInit, ViewChild, ChangeDetectorRef, } from '@angular/core';
import { NavParams, IonSearchbar, ModalController } from '@ionic/angular';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { environment } from '../../../environments/environment';
import { MapboxService } from '../../services/mapbox.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-carte-recherche',
  templateUrl: './carte-recherche.page.html',
  styleUrls: ['./carte-recherche.page.scss'],
})
export class CarteRecherchePage implements OnInit {

  @ViewChild('searchbar') searchbar: IonSearchbar;

  // mapbox
  public map: mapboxgl.Map;
  style: any;
  control: MapboxGeocoder;
  parGPS = false;
  autocompleteItems: any;
  autocomplete: any;
  acService: any;
  placesService: any;
  public query = '';
  latitude = 0;
  longitude = 0;
  adresseListe;
  json: any = [];
  itemSelected = null;
  el: any; // element html du marker
  result = [];
  currentMarker = null;
  public geolocEnCours = false;

  // si translate
  /*
  public RECHERCHERADRESSE;
  public VERIFIERADRESSE;
  public ADRESSE;
  */

  constructor(
    public modalCtrl: ModalController,
    public mapboxService: MapboxService,
    public ref: ChangeDetectorRef,
    private geolocation: Geolocation,
    private userService: UserService
    // public navParams: NavParams,
    // public translate: TranslateService
  ) {
    mapboxgl.accessToken = environment.mapbox.accessToken;

    // si translate
    /*
  this.translate.get('RECHERCHERADRESSE').subscribe(value => { this.RECHERCHERADRESSE = value; });
  this.translate.get('VERIFIERADRESSE').subscribe(value => { this.VERIFIERADRESSE = value; });
  this.translate.get('ADRESSE').subscribe(value => { this.ADRESSE = value; });
  */
  }

  checkLoc() {
    //console.log('checkLoc()');
    this.geolocEnCours = true;
    this.query = '';
    if (this.currentMarker) { this.currentMarker.remove(); }

    this.geolocation.getCurrentPosition().then((resp) => {
      // resp.coords.latitude
      // resp.coords.longitude
      this.latitude = resp.coords.latitude;
      this.longitude = resp.coords.longitude;
      this.geolocEnCours = false;
      this.map.flyTo({
        center: [this.longitude, this.latitude],
        zoom: 15
      });
      this.checkByGps();
    }).catch((error) => {
      //console.log('Error getting location', error);
    });
  }
  checkByGps() {
    //console.log('checkByGps()');
    this.mapboxService.API('get_adress_from_gps', { latitude: this.latitude, longitude: this.longitude })
      .then((data: any) => {
        this.json = data;
        this.ref.detectChanges();
        //console.log(this.json);
      })
      .catch((error) => {
        //console.log(error);
      });
  }

  cancel() {
    //console.log('cancel()');
    this.json = [];
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  validation() {
    console.log('validation()',this.itemSelected);
    this.modalCtrl.dismiss(this.itemSelected);
  }


  chooseItem(item: any, direct: boolean = false) {
    //console.log('modal > chooseItem > item > ', item);
    // this.query = item.place_name;

    if (this.currentMarker) { this.currentMarker.remove(); }
    this.json = null; // suppression du listing
    this.itemSelected = item;
    // this.latitude = item.data.geometry.coordinates[1];
    // this.longitude = item.data.geometry.coordinates[0];
    if (direct) {
      this.map.flyTo({
        center: item.center
      });
    } else {
      this.map.flyTo({
        center: item.center,
        zoom: 18
      });
    }
    this.el = document.createElement('div');
    this.el.classList.add('marker');
    const pop = `<p><strong>VOTRE ADRESSE</strong><br/>${item.place_name}</p>`;
    // el.addEventListener('click', () => { alert("Marker Clicked."); });
    const popup = new mapboxgl.Popup({ offset: 50 }).setHTML(pop);

    this.currentMarker = new mapboxgl.Marker(this.el, { offset: [25, 25] })
      .setLngLat(item.center)
      .addTo(this.map)
      // .setPopup(popup)
      .on('click', (event) => {
        //console.log('click', event);
      });
    // this.modalCtrl.dismiss(item);
  }

  async updateSearch() {
    //console.log('modal > updateSearch');
    //console.log(this.query);
    if (this.query) {
      if (this.query.length >= 5) {
        this.mapboxService.API('get_listAdress_from_adress', this.query)
          .then((data: any) => {
            this.json = data;
            this.ref.detectChanges();
            //console.log(this.json);
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  }

  ngOnInit() {
    //console.log('ngOnInit()');
  }

  ionViewWillEnter() {
    //console.log('ionViewWillEnter()');
    let zoum: any = 2;
    /*if (this.userService.user.coord) {
      zoum = 5;
    } else {
      zoum = 2;
    }*/
    /// MAPBOX ///
    this.map = new mapboxgl.Map({
      container: 'map',
      style: environment.mapbox.style,
      center: [ this.mapboxService.defaultproximity[1], this.mapboxService.defaultproximity[0]],
      zoom : zoum
    });
    //console.log(this.map);
    this.map.on('load', () => {
      this.map.resize();
      //console.log('carte loadée');
      this.ref.detectChanges();
    });
    this.map.on('click', (event) => {
      //console.log('event', event);
      const gps = {
        latitude: event['lngLat'].lat,
        longitude: event['lngLat'].lng,
      };
      this.mapboxService.API('get_adress_from_gps', gps).then(gpsPoint => {
        //console.log('gpsPoint', gpsPoint);
        if (gpsPoint.features.length > 0) {
          //console.log('RECUP PREMIER');
          this.chooseItem(gpsPoint.features[0], true);
        }
      });
    });
  }
}
