import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.page.html',
  styleUrls: ['./textarea.page.scss'],
})
export class TextareaPage implements OnInit {

  @Input() placeholder: string;
  @Input() title: string;
  text = null;

  constructor(
    public popoverCtrl: PopoverController,
  ) { }

  ngOnInit() {
  }

}
