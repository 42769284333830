import { NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import * as firebase from 'firebase/app';
import { AuthentificationService } from '../services/authentification.service';

@Injectable({
  providedIn: 'root'
})
export class HandleConnectGuard implements CanActivate {

  constructor(
    public navCtrl: NavController,
    public authService: AuthentificationService,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log('hello');
    let token = next.queryParams.token;
    if (token) {
      await firebase.auth().signInWithCustomToken(token);
    }
    if (this.authService.authWindow != null)
      this.authService.authWindow.close();
    return false;
  }
}
