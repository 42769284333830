import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { Countries } from '../../app/data.js';

@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  public limite = 9;
  public token = environment.mapbox.accessToken;
  public defaultproximity = [46, 2]; // france

  constructor(
    public http: Http,
    public userService: UserService,
  ) {

    // récupération du centrage et proximity de la carte par default
    /*
    if (this.userService.user.coord) { // récupère les coords du client
      this.defaultproximity = [
        this.userService.user.coord.latitude,
        this.userService.user.coord.longitude
      ];
    } else { // sinon celle du pays du user
      let codePays = 'FR';
      const tabCoordTemp = Countries.filter(e => e.code.includes(codePays));
      if (tabCoordTemp.length > 0) {
        this.defaultproximity = tabCoordTemp[0].latlng;
      }
    }
    //console.log( 'Voici les les coordonées de la proximité', this.defaultproximity);
    */
  }

  API(method: string, arg: any) {
    return this[method](arg);
  }

  /*
  this.http.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + this.selectedCity + '.json?proximity=-0.2995785,44.7002222&limit=10&access_token=' + this.token)
      .subscribe(data => {
            this.placeList = data['features'];
      });
  */

  public get_listAdress_from_adress(arg: any) {
    //console.log('arg = ', arg, this.defaultproximity[1], this.defaultproximity[0]);
    const lestypes = 'country,place,locality,address,poi';
    // country, region,postcode,district,place,locality,neighborhood,address, poi
    return new Promise(resolve => {
        // this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${arg}.json?proximity=-2,47&limit=10&access_token=${this.token}`)
        this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${arg}.json?proximity=${this.defaultproximity[1]},${this.defaultproximity[0]}&types=${lestypes}&limit=${this.limite}&access_token=${this.token}`)
        .subscribe(data => {
          resolve(data.json());
        }, err => {
          resolve(err);
        });
    });
  }

  getCoord(type: string, arg: string) {
    //console.log(type);
    // limite via country "countries":"fr",
    return this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/{"query":${arg}}.json?types=${type}&access_token=${this.token}`).toPromise();
  }

  public get_adress_from_gps(arg: any) {
    //console.log('get_adress_from_gps', arg);
    const lestypes = 'address';
    return new Promise(resolve => {
      this.http.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${arg.longitude},${arg.latitude}.json?types=${lestypes}&limit=${this.limite}&access_token=${this.token}`)
        .subscribe(data => {
          resolve(data.json());
        }, err => {
          resolve(err);
        });
    });
  }

  /*handleError(error) {
    console.error(error);
    return Observable.throw(error.json().error || 'Server error');
  }*/
}
