import { NavController } from '@ionic/angular';
import { AuthentificationService } from './../services/authentification.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserConnectedGuard implements CanActivate {

  constructor(
    public authService: AuthentificationService,
    public navCtrl: NavController,
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let canPass = null;
    await new Promise<boolean>(async (resolve) => {
      if (this.authService.isConnected === true) { resolve(true); }
      if (this.authService.isConnected === false) {
        resolve(false);
      }
      if (this.authService.isConnected === null) {
        const temp = await this.authService.authSubject.subscribe(async () => {
          if (this.authService.isConnected === false) {
            resolve(false);
          }
          await temp.unsubscribe();
          resolve(true);
        });
      }
    }).then(value => {
      canPass = value;
    });
    if (!canPass) {
      this.navCtrl.navigateRoot('/connexion');
    }
    return canPass;
  }
}
