import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { auth } from 'firebase';
import { Component } from '@angular/core';

import { Platform, ModalController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { CguPage } from './pages/cgu/cgu.page';
import { OneSignal } from '@ionic-native/onesignal/ngx';

import { environment } from '../environments/environment';
import { GlobalService } from './services/global.service';
import { ModelService } from 'src/app/services/model.service';
import { AuthentificationService } from './services/authentification.service';
import { ElasticsearchService } from './services/elasticsearch.service';

import * as firebase from 'firebase/app';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {


 

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public authService: AuthentificationService,
    public globalService: GlobalService,
    public modelService: ModelService,
    public modalCtrl: ModalController,
    public userService: UserService,
    public navCtrl: NavController,
    public router: Router,
    public elastic: ElasticsearchService,
  ) {
    this.authService.userHandling();
    this.initializeApp();
  }


  nouveauReseau() {
    this.navCtrl.navigateForward('/nouveau-reseau/info-basiques');
  }
  
  /*async putAll() {
    await this.elastic.createIndex().subscribe(body => {
      console.log(body.json());
    });
  }*/

  initializeApp() {
    this.platform.ready().then(() => {
      this.globalService.verificationVersion();

      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();

      }
    });

   
  }

  justOne = 1;

  isActive() {
    // console.log(this.router.url);
    return this.router.url.indexOf('/nouveau-reseau') != -1;
  }

  avancement() {
    if (this.router.url.split('/')[1] && this.router.url.split('/')[1] == 'nouveau-reseau') {
      let step = this.router.url.split('/')[2];
      switch(step) {
        case 'info-basiques': return 0.04;
        case 'info-reseau'  : return 0.20;
        case 'info-membre'  : return 0.36;
        case 'adherer'      : return 0.52;
        case 'contact'      : return 0.68;
        case 'finalisation' : return 0.84;
      }
    }
    return 0;
  }

  public devWidth = this.platform.width();
  
}