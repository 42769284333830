import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { GlobalService } from '../../services/global.service';
import { AngularFirestore } from '@angular/fire/firestore';

import * as moment from 'moment';
import * as firebase from 'firebase/app';

@Component({
	selector: 'app-create',
	templateUrl: './create.component.html',
	styleUrls: ['./create.component.scss'],
})
export class CreateComponent implements OnInit {

	public  history = [];
	public  historyOpen = false;

	///// NG MODEL //////////
	additif: any;
	@Input()
	get model() {
		return this.additif;
	}
	@Output() modelChange = new EventEmitter<string>();
	set model(val) {
		this.additif = val;
		this.modelChange.emit(this.additif);
	}
	///////////////////////
	
	@Input() modeEdition:boolean = true;
	@Output() record = new EventEmitter<any>();
	@Output() update = new EventEmitter<any>();
	@Output() edition = new EventEmitter<any>();

	public sauvegarde:any;
	public date_rec: any;

	constructor(
		public modalCtrl:ModalController,
		public toastCtrl: ToastController,
		public globalService : GlobalService, 
		public db: AngularFirestore,
	) { 

		this.sauvegarde = {...this.additif};
	}

	enregistrer() {
		this.record.emit();
	}

	getListing(type: string = 'articles', futur: boolean = false) {
		// console.log('getListing ', type);
		let requete;
		const start = new Date();
		if (futur) {
		  requete = this.db.collection(type, ref => ref.where('statut', '==', true).where('date_rec', '>=', start).orderBy('date_rec', 'asc'))
		} else {
		  requete = this.db.collection(type, ref => ref.where('statut', '==', true).orderBy('date_rec', 'desc'))
		}// where('date_rec', '>=', start)
		requete.valueChanges().subscribe(data => {
		  // console.log('getListing : '+type, data);
		  this['listing_' + type] = data;
		});
	  }


	historique(){
		this.historyOpen = !this.historyOpen;
		if(this.historyOpen){
			const requete = this.db.collection('additifs_history/'+this.additif.additifID+'/history', ref => ref.orderBy('date_update', 'desc'))
				.valueChanges().subscribe(data => {
					console.log('historique : ', data);
					this.history = data;
				});
		}
	}

	chargeHistorique(additifTime){
		console.log(additifTime)
		this.additif = additifTime;
		console.log(this.additif);
		this.modelChange.emit(this.additif);
		/*this.db.collection('additifs_history/'+this.additif.additifID+'/history').doc(historyID).valueChanges().subscribe((data)=>{
			console.log('data', data);
			if(data != undefined){
			  this.additif = data;
			}
		}*/
	}

	onChangedEdition() {
		this.edition.emit( this.modeEdition);
	}

	ngOnInit() {
		// console.log'app-create : ',this.additif);
		// this.date_rec = moment(this.additif.date_rec.seconds * 1000).format();
		// this.date_rec = {...this.additif.date_rec.toDate()};
		this.sauvegarde = {...this.additif};
		// console.log'datas sauvegarde', this.sauvegarde);
		// console.log'compare : ', Object.is(this.additif, this.sauvegarde ));
	}

	// undo(){
	// 	// console.log'undo()',this.additif,this.sauvegarde);
	// 	// this.additif = {...this.sauvegarde};
	// 	// console.log'undo()',this.additif,this.sauvegarde);
	// 	for (const key in this.sauvegarde) {
	// 		if (this.sauvegarde.hasOwnProperty(key)) {
	// 			const element = this.sauvegarde[key];
	// 			this.additif[key] = element;
	// 			// console.log(element);
				
	// 		}
	// 	}
	// 	 this.update.emit();
	// }
	comparaison(){
		const verif = Object.is(this.additif, this.sauvegarde )
		// console.log('comparaison :',verif,this.additif, this.sauvegarde );
		return verif;
	}

	onDateChange(event){
		 // console.log'DATE:', event.timeStamp, this.date_rec, this.additif.date_rec);
		 
		 //this.additif.date_rec = new Date(this.date_rec);
		 // this.additif.date_rec = firebase.firestore.Timestamp.fromDate(new Date(this.date_rec));
		 // this.date_rec  = event.timeStamp;
		 //this.additif.date_rec = new Date(event.timeStamp);
		 // console.log this.date_rec, this.additif.date_rec);
	}	

}
