import { AlertController, Platform, ModalController, LoadingController } from '@ionic/angular';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Camera, CameraOptions } from '@ionic-native/camera';
import { ResizePage } from '../../modals/resize/resize.page';

import * as firebase from 'firebase/app';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-image-profil',
  templateUrl: './image-profil.component.html',
  styleUrls: ['./image-profil.component.scss'],
})
export class ImageProfilComponent implements OnInit {


  URLValue: string = null;
  @Input()
  get URL() {
    return this.URLValue;
  }
  @Output() URLChange = new EventEmitter<string>();
  set URL(value: string) {
    this.URLValue = value;
    this.URLChange.emit(this.URLValue);
  }

  @Input() path: string;

  saveBDD = new Subject();

  base64 = null;
  noImage = null;

  constructor(
    public alertCtrl: AlertController,
    public platform: Platform,
    public modalCtrl: ModalController,
    public loadingCtrl: LoadingController,
  ) {
    this.saveBDD.subscribe(() => {


      let img = new Image();
      img.src = 'data:image/jpeg;base64,' + this.base64;

      img.onload = async () => {


        let canvas = document.createElement('canvas');
        canvas.width = 256;
        canvas.height = 256;
        let ctx = canvas.getContext('2d');
        let ratio = 256 / img.naturalWidth;
        // console.log('ratio', ratio);
        ctx.drawImage(img, 0, 0, img.naturalWidth * ratio, img.naturalHeight * ratio);

      
        // console.log(canvas.height);
        let finalBase64 = canvas.toDataURL();
        // console.log('finalBase64', finalBase64);

        const array = [];
        const binary = atob(finalBase64.split(',')[1]);
        for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
        }
        
        const loading = await this.loadingCtrl.create({
          message: 'Travail en cours',
          duration: 2000
        });
        await loading.present();
  
        await firebase.storage().ref().child(this.path).put(new Blob([new Uint8Array(array)], { type: 'image/jpeg' })).then(async snapshot => {
          await snapshot.ref.getDownloadURL().then(url => {
            this.noImage = false;
            this.URL = url;
            loading.dismiss();
          });
        }).catch( () => {
          loading.dismiss();
        });
  
        this.base64 = null;
      }

      //console.log(ctx.getImageData(0, 0, 512, 512), ctx.getImageData(0, 0, 512, 512).data);
      //let finalBase64 = canvas.toDataURL();



      
      /*const array = [];
      const binary = atob(this.base64);
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      console.log('array', array);
      const loading = await this.loadingCtrl.create({
        message: 'Travail en cours',
        duration: 2000
      });
      await loading.present();

      await firebase.storage().ref()
      .child(this.path)
      .put(new Blob([new Uint8Array(array)], { type: 'image/jpeg' }))
      .then(async snapshot => {
        await snapshot.ref.getDownloadURL().then(url => {
          this.noImage = false;
          this.URL = url;
          loading.dismiss();
        });
      }).catch( () => {
        loading.dismiss();
      });

      this.base64 = null;*/
    });
  }

  async resetImg() {
    this.noImage = true;
    this.URL = environment.avatar;
    this.base64 = null;
    await firebase.storage().ref()
      .child(this.path)
      .delete();
  }

  async resizeImg(imageData: string) {
    const modal = await this.modalCtrl.create({
      component: ResizePage,
      componentProps: {
        'image': 'data:image/jpeg;base64,' + imageData,
        'ratio': 1/1,
      }
    });
    modal.onDidDismiss().then(data => {
      this.noImage = false;
      this.base64 = data.data.split(',')[1];
      this.saveBDD.next();
    });
    await modal.present();
  }

  async photoSource() {
    const alert = await this.alertCtrl.create({
      header: "Ajout de photo",
      message: "Choisir la source",
      mode: 'ios',
      cssClass: 'alertPhoto',
      buttons: [
        {
          text: "Galerie",
          cssClass: 'gallery',
          handler: () => this.addPhoto(1)
        },
        {
          text: "Appareil",
          cssClass: 'camera',
          handler: () => this.addPhoto(0)
        },
        {
          text: "Annuler",
          role: 'close',
        },
      ]
    });
    await alert.present();
  }

  async addPhotoFromPC() {
    let fileData = document.getElementById('inputFile')['files'][0];

    if (fileData && (fileData['type'] == 'image/jpeg' || fileData['type'] == 'image/png')) {
      let reader = new FileReader;

      reader.onload = () => {
        this.resizeImg(reader.result.toString().split(',')[1]);
        document.getElementById('inputFile')['value'] = "";
      }
      reader.readAsDataURL(fileData);
    }
  }

  addPhoto(source: number) {
    let options: CameraOptions = {
      quality: 90,
      destinationType: Camera.DestinationType.DATA_URL,
      encodingType: Camera.EncodingType.JPEG,
      mediaType: Camera.MediaType.PICTURE,
      sourceType : (source == 1) ? Camera.PictureSourceType.PHOTOLIBRARY : Camera.PictureSourceType.CAMERA,
      cameraDirection : Camera.Direction.FRONT,
      correctOrientation: true,
      targetHeight: 512
    }
    Camera.getPicture(options).then(imageData => {
      this.resizeImg(imageData);
    });
  }

  ngOnInit() {
    if (this.URL == environment.avatar)
      this.noImage = true;
    else
      this.noImage = false;
  }

}