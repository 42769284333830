import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RechercheDialPage } from '../../modals/recherche-dial/recherche-dial.page';
import { ModalController } from '@ionic/angular';
import { AuthentificationService } from '../../services/authentification.service';
import * as libphonenumber from 'google-libphonenumber';
import { Countries } from '../../data';

@Component({
  selector: 'app-le-telephone',
  templateUrl: './le-telephone.component.html',
  styleUrls: ['./le-telephone.component.scss'],
})
export class LeTelephoneComponent implements OnInit {

  @Input() telephone = '';
  @Output() telephoneChanged = new EventEmitter<any>();

  // public telephone = '+330685' ;
  public isValidNumber: boolean;
  public callCode = {
    code: 'FR',
    name: 'France',
    call: '33'
  };

  constructor(
    public modalCtrl: ModalController,
    public authService: AuthentificationService
  ) { }

  ngOnInit() {}

  onTelephoneChanged() {
    this.telephoneChanged.emit( this.telephone );
  }

  formatTel() {
    // supprime les caractères pour éviter les erreurs
    this.telephone =  this.telephone.replace(/[^ +0-9]/g, '');
    let lephone: string;
    lephone = this.telephone;
    let countryCode: string;

    // default
    // countryCode =  this.authService.callCode.code;

    if (this.telephone.length > 6 ) {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const PNF = libphonenumber.PhoneNumberFormat;
      let pNumber;
      pNumber = phoneUtil.parseAndKeepRawInput( lephone, countryCode);
      this.isValidNumber = phoneUtil.isPossibleNumber(pNumber);
      this.telephone = phoneUtil.format(pNumber, PNF.INTERNATIONAL);
      // modification du drapeau
      if ( phoneUtil.getRegionCodeForNumber(pNumber)) {
        if ( phoneUtil.getRegionCodeForNumber(pNumber) !== countryCode) {
            this.verifDrapeau(this.telephone);
          // this.authService.callCode.code = phoneUtil.getRegionCodeForNumber(pNumber);
          // this.authService.callCode.call = pNumber.getCountryCode();
        }
      }
    }
  }
  verifDrapeau(dial) {
    let recupDial = dial.match(/\+[0-9]{1,4}/);
    if (recupDial) {
      recupDial = recupDial[0].replace('+', '');
      if (recupDial !== this.callCode.call) {
        const tableau = Countries.filter(
          (data) => {
            return data.call === recupDial;
          }
        );
        if (tableau.length > 0) { this.callCode = tableau[0]; }
      }
    }
  }
  recDial(event) {
    this.onTelephoneChanged();
  }

  async openDialModal() {
    const modal = await this.modalCtrl.create({
      component: RechercheDialPage,
      componentProps: {
        //countryChecked: this.authService.callCode.call
        countryChecked: 'fr'
      }
    });
    modal.onDidDismiss().then((data) => {
      if (data.data) {
        this.callCode = data.data;
        this.telephone = '+' + this.callCode.call;
        this.formatTel();
      }
    });
    await modal.present();
  }

}
