import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

type blocCaseModel = {
  type: 'text' | 'image' | 'quote',
  text: string,
  image: string[]
}

@Component({
  selector: 'app-editor-templates',
  templateUrl: './editor-templates.page.html',
  styleUrls: ['./editor-templates.page.scss'],
})
export class EditorTemplatesPage implements OnInit {

  step: number = 1;

  blocConfig: number[][] = [[1, 0, 0],
                            [0, 0, 0]];
  bloc: blocCaseModel[][] = [[], []];
  selectedCase: number[] = [0, 0];
  addTemp: boolean = false;

  constructor(
    public modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }

  changeConfig(index: number) {
    console.log(`changeConfig(${index})`);
    switch(index) {
      case 1:
        this.blocConfig = [[1, 0, 0], [0, 0, 0]];
        break;
      case 2:
        this.blocConfig = [[1, 1, 0], [0, 0, 0]];
        break;
      case 3:
        this.blocConfig = [[1, 1, 1], [0, 0, 0]];
        break;
      case 4:
        this.blocConfig = [[1, 0, 0], [1, 0, 0]];
        break;
      case 5:
        this.blocConfig = [[1, 1, 0], [1, 1, 0]];
        break;
      case 6:
        this.blocConfig = [[1, 1, 1], [1, 1, 1]];
        break;
    }    
  }

  setBlocCases() {
    this.bloc = [];
    let rowIndex = 0;
    for (let row of this.blocConfig) {
      if (row[0] == 1) {
        this.bloc.push([]);
        for (let col of row) {
          if (col == 1) {
            this.bloc[rowIndex].push({
              type: null,
              image: [],
              text: '',
            });
          }
        }
        rowIndex++;
      }
    }
    console.log(this.bloc);
  }

  setTemplate(type: 'text' | 'image' | 'quote') {
    this.bloc[this.selectedCase[1]][this.selectedCase[0]].type = type;
  }

  validate() {
    for (let row of this.bloc) {
      for (let col of row) {
        if (!col.type) {
          return;
        }
      }
    }
    this.modalCtrl.dismiss(this.bloc);
  }
}
