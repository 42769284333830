import { Pipe, PipeTransform } from '@angular/core';
import { Countries } from '../data';

@Pipe({
  name: 'pays'
})
export class PaysPipe implements PipeTransform {

  transform(value: any): any {
    let valeur = value.toUpperCase();
    for (const p of Countries) {
      if ( p.code === value ) { valeur = p.name; }
    }
    return valeur;
  }
}
