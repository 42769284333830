import { UserService } from './../../services/user.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthentificationService } from '../../services/authentification.service';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-tete',
  templateUrl: './tete.component.html',
  styleUrls: ['./tete.component.scss'],
})
export class TeteComponent implements OnInit {

  @Input() titre: any;
  @Input() modal = false;
  @Input() couleurdeFond: any;
  @Output() action = new EventEmitter<any>();
  public translucide = false;

  constructor(
    public router: Router,
    public authService: AuthentificationService,
    public userService: UserService,
    public modalCtrl: ModalController,
    public route: NavController,
  ) {
  }
  ngOnInit() {
    if (this.couleurdeFond === 'transparent') {
      this.translucide = true;
    } else {
      this.translucide = false;
    }
  }
  close() {
    this.action.emit();
  }
  /*
  async goToFiltres() {
    // this.router.navigateByUrl('/filtres');
    const modal = await this.modalCtrl.create({
      component: FiltresPage,
    });
    modal.onDidDismiss().then((data: any) => {
      //console.log('dismiss', data.data);
    });
    await modal.present();
  }
  */
 open( page, direction ){
   if(direction == 'forward') {  this.route.navigateForward(page); }
   if(direction == 'root') {  this.route.navigateRoot(page); }
   if(direction == 'back') {  this.route.navigateBack(page); }
 }
 openSearch(){
  this.router.navigateByUrl('/reseaux');
 }



  goToCarto() {
    this.router.navigateByUrl('/projets/carte');
  }
  goToNotifications() {
    this.router.navigateByUrl('/notifications');
  }


}
