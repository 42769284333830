import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'ion-triple-toggle',
  templateUrl: './ion-triple-toggle.component.html',
  styleUrls: ['./ion-triple-toggle.component.scss'],
})
export class IonTripleToggleComponent implements OnInit, AfterViewInit {

  toggleValue: boolean;
  @Input()
  get toggle() {
    return this.toggleValue;
  }
  @Output() toggleChange = new EventEmitter<boolean>();
  set toggle(val) {
    this.toggleValue = val;
    this.toggleChange.emit(this.toggleValue);
  }

  constructor() { }

  ngOnInit() {
    //console.log('model', this.toggle);
  }

  changeToggle() {
    document.querySelectorAll('input[type=radio]').forEach(element => {
      if (element['checked']) {
        //console.log(element.id);
        if (element.id == 'toggle_off') this.toggle = false;
        if (element.id == 'toggle_on') this.toggle = true;
      }
    });
  }

  ngAfterViewInit() {
    if (this.toggle === false) document.getElementById('toggle_off')['checked'] = true;
    if (this.toggle === null) document.getElementById('toggle_null')['checked'] = true;
    if (this.toggle === true) document.getElementById('toggle_on')['checked'] = true;
  }
}
