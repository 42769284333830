import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-pied',
  templateUrl: './pied.component.html',
  styleUrls: ['./pied.component.scss'],
})
export class PiedComponent implements OnInit {

  constructor(
    public route: NavController,
    public globalService : GlobalService,
  ) { }

  open( page, direction ){
    if(direction == 'forward') {  this.route.navigateForward(page); }
    if(direction == 'root') {  this.route.navigateRoot(page); }
    if(direction == 'back') {  this.route.navigateBack(page); }
  }

  ngOnInit() {}

}
