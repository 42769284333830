import { Pipe, PipeTransform } from '@angular/core';
import { GlobalService } from './../services/global.service';

@Pipe({
  name: 'client'
})
export class ClientPipe implements PipeTransform {

  constructor(private globalService: GlobalService) {}

  transform(value: any, args?: any): any {
    let valeur = '';
    for (const p of this.globalService.datajs.Listes) {
      if ( p.CODE === value ) { valeur = p[args]; }
    }
    return valeur;
  }
}
