import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TeteComponent } from './tete/tete.component';
import { LeTelephoneComponent } from './le-telephone/le-telephone.component';
import { LAdresseComponent } from './l-adresse/l-adresse.component';
import { PipesModule } from '../pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { LinkPreviewComponent } from './link-preview/link-preview.component';
import { MediaComponent } from './media/media.component';
import { IonTripleToggleComponent } from './ion-triple-toggle/ion-triple-toggle.component';
import { ImageProfilComponent } from './image-profil/image-profil.component'
import { PiedComponent } from './pied/pied.component'
import { Routes, RouterModule } from '@angular/router';
import { ItemThumbnailComponent } from './item-thumbnail/item-thumbnail.component';
import { RechercherComponent } from './rechercher/rechercher.component';

import { CreateComponent } from './create/create.component';
import { MediasComponent } from './additive/medias/medias.component';
import { ListesComponent } from './additive/listes/listes.component';
import { ReglementationComponent } from './additive/reglementation/reglementation.component';
import { ScienceInfoComponent } from './additive/science-info/science-info.component';
import { FormerrorComponent } from './formerror/formerror.component';

import { IonTag } from './ion-tags-input/ion-tag';
import { IonTagsInput } from './ion-tags-input/ion-tags-input';
// import { IonTagsInputModule } from './ion-tags-input/ion-tag-input.module';

import { EditorModule } from '@tinymce/tinymce-angular';
import { RecapComponent } from './recap/recap.component';


@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        PipesModule,
        FormsModule,
        RouterModule,
        EditorModule,
    ],
    declarations: [
        TeteComponent,
        LeTelephoneComponent,
        LAdresseComponent,
        LinkPreviewComponent,
        MediaComponent,
        CreateComponent,
        MediasComponent,
        ListesComponent,
        ReglementationComponent,
        ScienceInfoComponent,
        IonTripleToggleComponent,
        ImageProfilComponent,
        PiedComponent,
        ItemThumbnailComponent,
        RechercherComponent,
        FormerrorComponent,
        RecapComponent,
        IonTagsInput, IonTag
    ],
    exports: [
        TeteComponent,
        LeTelephoneComponent,
        LAdresseComponent,
        LinkPreviewComponent,
        MediaComponent,
        CreateComponent,
        MediasComponent,
        ListesComponent,
        ReglementationComponent,
        EditorModule,
        ScienceInfoComponent,
        IonTripleToggleComponent,
        ImageProfilComponent,
        PiedComponent,
        ItemThumbnailComponent,
        RechercherComponent,
        FormerrorComponent,
        RecapComponent,
        IonTagsInput, IonTag
    ],
  entryComponents: [IonTagsInput, IonTag],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
