import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleancorrespondance'
})
export class BooleancorrespondancePipe implements PipeTransform {

  transform(value: any, args?: any): any {
  	return value ? 'oui' : 'non';
    // return 'salut';
  }

}
