export let Infos = [
{ code: 'GOUGET' , name:'GOUGET', couleurs : [ 'vert', 'orange', 'rouge']},
{ code: 'DENANS/laNutrition.fr' , name:'DENANS / laNutrition.fr', couleurs : [ 'vert', 'orange', 'rouge']},
{ code: 'QUECHOISIR' , name:'QUE CHOISIR', couleurs : [ 'vert', 'jaune', 'orange', 'rouge']},
{ code: 'YUKA' , name:'Yuka', couleurs : [ 'vert',  'jaune',  'orange',  'rouge'] },
{ code: 'CARREFOUR' , name:'CARREFOUR', couleurs : [ 'vert', 'rouge']},
{ code: 'SYSTEMEU' , name:'SYSTEME U', couleurs : [ 'vert', 'rouge']},
// { code: 'OPENFOODFACTS' , name:'OPEN FOOD FACTS', couleurs : [ 'vert', 'orange', 'rouge']}, // //non connu 
{ code: '60MILLIONS' , name:'60 MILLIONS DE CONSOMMATEURS', couleurs : [ 'vert', 'rouge']}
];

export let ScienceCode = [
{ code: 'SCF' , name:'Scf'},
{ code: 'JECFA' , name:'Jecfa'},
{ code: 'EFSA' , name:'Efsa'},
{ code: 'TEMANORD' , name:'Temanord'},
{ code: 'ANSES' , name:'Anses'},
];

export let Listes = [
   { code: 'FOODINNOV', name: 'Foodinnov', couleurs: [ 'rouge', 'jaune', 'orange' ] },
   { code: 'INTERMARCHE', name: 'Aramis', couleurs: [ 'rouge', 'vert', 'orange' ] },
];


export let Months = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
]

export const Countries = [  
  {  
     "name":"Afghanistan",
     "code":"AF",
     "call":"93",
     "latlng":[  
        33,
        65
     ]
  },
  {  
     "name":"Albania",
     "code":"AL",
     "call":"355",
     "latlng":[  
        41,
        20
     ]
  },
  {  
     "name":"Algeria",
     "code":"DZ",
     "call":"213",
     "latlng":[  
        28,
        3
     ]
  },
  {  
     "name":"American Samoa",
     "code":"AS",
     "call":"1684",
     "latlng":[  
        -14.33333333,
        -170
     ]
  },
  {  
     "name":"Andorra",
     "code":"AD",
     "call":"376",
     "latlng":[  
        42.5,
        1.5
     ]
  },
  {  
     "name":"Angola",
     "code":"AO",
     "call":"244",
     "latlng":[  
        -12.5,
        18.5
     ]
  },
  {  
     "name":"Anguilla",
     "code":"AI",
     "call":"1264",
     "latlng":[  
        18.25,
        -63.16666666
     ]
  },
  {  
     "name":"Antarctica",
     "code":"AQ",
     "call":"672",
     "latlng":[  
        -90,
        0
     ]
  },
  {  
     "name":"Antigua and Barbuda",
     "code":"AG",
     "call":"1268",
     "latlng":[  
        17.05,
        -61.8
     ]
  },
  {  
     "name":"Argentina",
     "code":"AR",
     "call":"54",
     "latlng":[  
        -34,
        -64
     ]
  },
  {  
     "name":"Armenia",
     "code":"AM",
     "call":"374",
     "latlng":[  
        40,
        45
     ]
  },
  {  
     "name":"Aruba",
     "code":"AW",
     "call":"297",
     "latlng":[  
        12.5,
        -69.96666666
     ]
  },
  {  
     "name":"Australia",
     "code":"AU",
     "call":"61",
     "latlng":[  
        -27,
        133
     ]
  },
  {  
     "name":"Austria",
     "code":"AT",
     "call":"43",
     "latlng":[  
        47.33333333,
        13.33333333
     ]
  },
  {  
     "name":"Azerbaijan",
     "code":"AZ",
     "call":"994",
     "latlng":[  
        40.5,
        47.5
     ]
  },
  {  
     "name":"Bahamas",
     "code":"BS",
     "call":"1242",
     "latlng":[  
        24.25,
        -76
     ]
  },
  {  
     "name":"Bahrain",
     "code":"BH",
     "call":"973",
     "latlng":[  
        26,
        50.55
     ]
  },
  {  
     "name":"Bangladesh",
     "code":"BD",
     "call":"880",
     "latlng":[  
        24,
        90
     ]
  },
  {  
     "name":"Barbados",
     "code":"BB",
     "call":"1246",
     "latlng":[  
        13.16666666,
        -59.53333333
     ]
  },
  {  
     "name":"Belarus",
     "code":"BY",
     "call":"375",
     "latlng":[  
        53,
        28
     ]
  },
  {  
     "name":"Belgium",
     "code":"BE",
     "call":"32",
     "latlng":[  
        50.83333333,
        4
     ]
  },
  {  
     "name":"Belize",
     "code":"BZ",
     "call":"501",
     "latlng":[  
        17.25,
        -88.75
     ]
  },
  {  
     "name":"Benin",
     "code":"BJ",
     "call":"229",
     "latlng":[  
        9.5,
        2.25
     ]
  },
  {  
     "name":"Bermuda",
     "code":"BM",
     "call":"1441",
     "latlng":[  
        32.33333333,
        -64.75
     ]
  },
  {  
     "name":"Bhutan",
     "code":"BT",
     "call":"975",
     "latlng":[  
        27.5,
        90.5
     ]
  },
  {  
     "name":"Bolivia",
     "code":"BO",
     "call":"591",
     "latlng":[  
        -17,
        -65
     ]
  },
  {  
     "name":"Bosnia and Herzegovina",
     "code":"BA",
     "call":"387",
     "latlng":[  
        44,
        18
     ]
  },
  {  
     "name":"Botswana",
     "code":"BW",
     "call":"267",
     "latlng":[  
        -22,
        24
     ]
  },
  {  
     "name":"Brazil",
     "code":"BR",
     "call":"55",
     "latlng":[  
        -10,
        -55
     ]
  },
  {  
     "name":"British Indian Ocean Territory",
     "code":"IO",
     "call":"246",
     "latlng":[  
        -6,
        71.5
     ]
  },
  {  
     "name":"Brunei",
     "code":"BN",
     "call":"673",
     "latlng":[  
        4.5,
        114.66666666
     ]
  },
  {  
     "name":"Bulgaria",
     "code":"BG",
     "call":"359",
     "latlng":[  
        43,
        25
     ]
  },
  {  
     "name":"Burkina Faso",
     "code":"BF",
     "call":"226",
     "latlng":[  
        13,
        -2
     ]
  },
  {  
     "name":"Burundi",
     "code":"BI",
     "call":"257",
     "latlng":[  
        -3.5,
        30
     ]
  },
  {  
     "name":"Cambodia",
     "code":"KH",
     "call":"855",
     "latlng":[  
        13,
        105
     ]
  },
  {  
     "name":"Cameroon",
     "code":"CM",
     "call":"237",
     "latlng":[  
        6,
        12
     ]
  },
  {  
     "name":"Canada",
     "code":"CA",
     "call":"1",
     "latlng":[  
        60,
        -95
     ]
  },
  {  
     "name":"Cape Verde",
     "code":"CV",
     "call":"238",
     "latlng":[  
        16,
        -24
     ]
  },
  {  
     "name":"Cayman Islands",
     "code":"KY",
     "call":"1345",
     "latlng":[  
        19.5,
        -80.5
     ]
  },
  {  
     "name":"Central African Republic",
     "code":"CF",
     "call":"236",
     "latlng":[  
        7,
        21
     ]
  },
  {  
     "name":"Chad",
     "code":"TD",
     "call":"235",
     "latlng":[  
        15,
        19
     ]
  },
  {  
     "name":"Chile",
     "code":"CL",
     "call":"56",
     "latlng":[  
        -30,
        -71
     ]
  },
  {  
     "name":"China",
     "code":"CN",
     "call":"86",
     "latlng":[  
        35,
        105
     ]
  },
  {  
     "name":"Christmas Island",
     "code":"CX",
     "call":"61",
     "latlng":[  
        -10.5,
        105.66666666
     ]
  },
  {  
     "name":"Cocos (Keeling) Islands",
     "code":"CC",
     "call":"61",
     "latlng":[  
        -12.5,
        96.83333333
     ]
  },
  {  
     "name":"Colombia",
     "code":"CO",
     "call":"57",
     "latlng":[  
        4,
        -72
     ]
  },
  {  
     "name":"Comoros",
     "code":"KM",
     "call":"269",
     "latlng":[  
        -12.16666666,
        44.25
     ]
  },
  {  
     "name":"Congo",
     "code":"CG",
     "call":"242",
     "latlng":[  
        -1,
        15
     ]
  },
  {  
     "name":"Cook Islands",
     "code":"CK",
     "call":"682",
     "latlng":[  
        -21.23333333,
        -159.76666666
     ]
  },
  {  
     "name":"Costa Rica",
     "code":"CR",
     "call":"506",
     "latlng":[  
        10,
        -84
     ]
  },
  {  
     "name":"Croatia",
     "code":"HR",
     "call":"385",
     "latlng":[  
        45.16666666,
        15.5
     ]
  },
  {  
     "name":"Cuba",
     "code":"CU",
     "call":"53",
     "latlng":[  
        21.5,
        -80
     ]
  },
  {  
     "name":"Cyprus",
     "code":"CY",
     "call":"357",
     "latlng":[  
        35,
        33
     ]
  },
  {  
     "name":"Czech Republic",
     "code":"CZ",
     "call":"420",
     "latlng":[  
        49.75,
        15.5
     ]
  },
  {  
     "name":"Denmark",
     "code":"DK",
     "call":"45",
     "latlng":[  
        56,
        10
     ]
  },
  {  
     "name":"Djibouti",
     "code":"DJ",
     "call":"253",
     "latlng":[  
        11.5,
        43
     ]
  },
  {  
     "name":"Dominica",
     "code":"DM",
     "call":"1767",
     "latlng":[  
        15.41666666,
        -61.33333333
     ]
  },
  {  
     "name":"Dominican Republic",
     "code":"DO",
     "call":"1849",
     "latlng":[  
        19,
        -70.66666666
     ]
  },
  {  
     "name":"East Timor",
     "code":"TP",
     "call":"670"
  },
  {  
     "name":"Ecuador",
     "code":"EC",
     "call":"593",
     "latlng":[  
        -2,
        -77.5
     ]
  },
  {  
     "name":"Egypt",
     "code":"EG",
     "call":"20",
     "latlng":[  
        27,
        30
     ]
  },
  {  
     "name":"El Salvador",
     "code":"SV",
     "call":"503",
     "latlng":[  
        13.83333333,
        -88.91666666
     ]
  },
  {  
     "name":"Equatorial Guinea",
     "code":"GQ",
     "call":"240",
     "latlng":[  
        2,
        10
     ]
  },
  {  
     "name":"Eritrea",
     "code":"ER",
     "call":"291",
     "latlng":[  
        15,
        39
     ]
  },
  {  
     "name":"Estonia",
     "code":"EE",
     "call":"372",
     "latlng":[  
        59,
        26
     ]
  },
  {  
     "name":"Ethiopia",
     "code":"ET",
     "call":"251",
     "latlng":[  
        8,
        38
     ]
  },
  {  
     "name":"Falkland Islands",
     "code":"FK",
     "call":"500",
     "latlng":[  
        -51.75,
        -59
     ]
  },
  {  
     "name":"Faroe Islands",
     "code":"FO",
     "call":"298",
     "latlng":[  
        62,
        -7
     ]
  },
  {  
     "name":"Fiji Islands",
     "code":"FJ",
     "call":"679",
     "latlng":[  
        -18,
        175
     ]
  },
  {  
     "name":"Finland",
     "code":"FI",
     "call":"358",
     "latlng":[  
        64,
        26
     ]
  },
  {  
     "name":"France",
     "code":"FR",
     "call":"33",
     "latlng":[  
        46,
        2
     ]
  },
  {  
     "name":"French Guiana",
     "code":"GF",
     "call":"594",
     "latlng":[  
        4,
        -53
     ]
  },
  {  
     "name":"French Polynesia",
     "code":"PF",
     "call":"689",
     "latlng":[  
        -15,
        -140
     ]
  },
  {  
     "name":"Gabon",
     "code":"GA",
     "call":"241",
     "latlng":[  
        -1,
        11.75
     ]
  },
  {  
     "name":"Gambia",
     "code":"GM",
     "call":"220",
     "latlng":[  
        13.46666666,
        -16.56666666
     ]
  },
  {  
     "name":"Georgia",
     "code":"GE",
     "call":"995",
     "latlng":[  
        42,
        43.5
     ]
  },
  {  
     "name":"Germany",
     "code":"DE",
     "call":"49",
     "latlng":[  
        51,
        9
     ]
  },
  {  
     "name":"Ghana",
     "code":"GH",
     "call":"233",
     "latlng":[  
        8,
        -2
     ]
  },
  {  
     "name":"Gibraltar",
     "code":"GI",
     "call":"350",
     "latlng":[  
        36.13333333,
        -5.35
     ]
  },
  {  
     "name":"Greece",
     "code":"GR",
     "call":"30",
     "latlng":[  
        39,
        22
     ]
  },
  {  
     "name":"Greenland",
     "code":"GL",
     "call":"299",
     "latlng":[  
        72,
        -40
     ]
  },
  {  
     "name":"Grenada",
     "code":"GD",
     "call":"1473",
     "latlng":[  
        12.11666666,
        -61.66666666
     ]
  },
  {  
     "name":"Guadeloupe",
     "code":"GP",
     "call":"590",
     "latlng":[  
        16.25,
        -61.583333
     ]
  },
  {  
     "name":"Guam",
     "code":"GU",
     "call":"1671",
     "latlng":[  
        13.46666666,
        144.78333333
     ]
  },
  {  
     "name":"Guatemala",
     "code":"GT",
     "call":"502",
     "latlng":[  
        15.5,
        -90.25
     ]
  },
  {  
     "name":"Guinea",
     "code":"GN",
     "call":"224",
     "latlng":[  
        11,
        -10
     ]
  },
  {  
     "name":"Guinea-Bissau",
     "code":"GW",
     "call":"245",
     "latlng":[  
        12,
        -15
     ]
  },
  {  
     "name":"Guyana",
     "code":"GY",
     "call":"592",
     "latlng":[  
        5,
        -59
     ]
  },
  {  
     "name":"Haiti",
     "code":"HT",
     "call":"509",
     "latlng":[  
        19,
        -72.41666666
     ]
  },
  {  
     "name":"Holy See (Vatican City State)",
     "code":"VA",
     "call":"39",
     "latlng":[  
        41.9,
        12.45
     ]
  },
  {  
     "name":"Honduras",
     "code":"HN",
     "call":"504",
     "latlng":[  
        15,
        -86.5
     ]
  },
  {  
     "name":"Hong Kong",
     "code":"HK",
     "call":"852",
     "latlng":[  
        22.267,
        114.188
     ]
  },
  {  
     "name":"Hungary",
     "code":"HU",
     "call":"36",
     "latlng":[  
        47,
        20
     ]
  },
  {  
     "name":"Iceland",
     "code":"IS",
     "call":"354",
     "latlng":[  
        65,
        -18
     ]
  },
  {  
     "name":"India",
     "code":"IN",
     "call":"91",
     "latlng":[  
        20,
        77
     ]
  },
  {  
     "name":"Indonesia",
     "code":"ID",
     "call":"62",
     "latlng":[  
        -5,
        120
     ]
  },
  {  
     "name":"Iran",
     "code":"IR",
     "call":"98",
     "latlng":[  
        32,
        53
     ]
  },
  {  
     "name":"Iraq",
     "code":"IQ",
     "call":"964",
     "latlng":[  
        33,
        44
     ]
  },
  {  
     "name":"Ireland",
     "code":"IE",
     "call":"353",
     "latlng":[  
        53,
        -8
     ]
  },
  {  
     "name":"Israel",
     "code":"IL",
     "call":"972",
     "latlng":[  
        31.47,
        35.13
     ]
  },
  {  
     "name":"Italy",
     "code":"IT",
     "call":"39",
     "latlng":[  
        42.83333333,
        12.83333333
     ]
  },
  {  
     "name":"Ivory Coast",
     "code":"CI",
     "call":"225",
     "latlng":[  
        8,
        -5
     ]
  },
  {  
     "name":"Jamaica",
     "code":"JM",
     "call":"1876",
     "latlng":[  
        18.25,
        -77.5
     ]
  },
  {  
     "name":"Japan",
     "code":"JP",
     "call":"81",
     "latlng":[  
        36,
        138
     ]
  },
  {  
     "name":"Jordan",
     "code":"JO",
     "call":"962",
     "latlng":[  
        31,
        36
     ]
  },
  {  
     "name":"Kazakhstan",
     "code":"KZ",
     "call":"7",
     "latlng":[  
        48,
        68
     ]
  },
  {  
     "name":"Kenya",
     "code":"KE",
     "call":"254",
     "latlng":[  
        1,
        38
     ]
  },
  {  
     "name":"Kiribati",
     "code":"KI",
     "call":"686",
     "latlng":[  
        1.41666666,
        173
     ]
  },
  {  
     "name":"Kuwait",
     "code":"KW",
     "call":"965",
     "latlng":[  
        29.5,
        45.75
     ]
  },
  {  
     "name":"Kyrgyzstan",
     "code":"KG",
     "call":"996",
     "latlng":[  
        41,
        75
     ]
  },
  {  
     "name":"Laos",
     "code":"LA",
     "call":"856",
     "latlng":[  
        18,
        105
     ]
  },
  {  
     "name":"Latvia",
     "code":"LV",
     "call":"371",
     "latlng":[  
        57,
        25
     ]
  },
  {  
     "name":"Lebanon",
     "code":"LB",
     "call":"961",
     "latlng":[  
        33.83333333,
        35.83333333
     ]
  },
  {  
     "name":"Lesotho",
     "code":"LS",
     "call":"266",
     "latlng":[  
        -29.5,
        28.5
     ]
  },
  {  
     "name":"Liberia",
     "code":"LR",
     "call":"231",
     "latlng":[  
        6.5,
        -9.5
     ]
  },
  {  
     "name":"Libyan Arab Jamahiriya",
     "code":"LY",
     "call":"218",
     "latlng":[  
        25,
        17
     ]
  },
  {  
     "name":"Liechtenstein",
     "code":"LI",
     "call":"423",
     "latlng":[  
        47.26666666,
        9.53333333
     ]
  },
  {  
     "name":"Lithuania",
     "code":"LT",
     "call":"370",
     "latlng":[  
        56,
        24
     ]
  },
  {  
     "name":"Luxembourg",
     "code":"LU",
     "call":"352",
     "latlng":[  
        49.75,
        6.16666666
     ]
  },
  {  
     "name":"Macao",
     "code":"MO",
     "call":"853",
     "latlng":[  
        22.16666666,
        113.55
     ]
  },
  {  
     "name":"North Macedonia",
     "code":"MK",
     "call":"389",
     "latlng":[  
        41.83333333,
        22
     ]
  },
  {  
     "name":"Madagascar",
     "code":"MG",
     "call":"261",
     "latlng":[  
        -20,
        47
     ]
  },
  {  
     "name":"Malawi",
     "code":"MW",
     "call":"265",
     "latlng":[  
        -13.5,
        34
     ]
  },
  {  
     "name":"Malaysia",
     "code":"MY",
     "call":"60",
     "latlng":[  
        2.5,
        112.5
     ]
  },
  {  
     "name":"Maldives",
     "code":"MV",
     "call":"960",
     "latlng":[  
        3.25,
        73
     ]
  },
  {  
     "name":"Mali",
     "code":"ML",
     "call":"223",
     "latlng":[  
        17,
        -4
     ]
  },
  {  
     "name":"Malta",
     "code":"MT",
     "call":"356",
     "latlng":[  
        35.83333333,
        14.58333333
     ]
  },
  {  
     "name":"Marshall Islands",
     "code":"MH",
     "call":"692",
     "latlng":[  
        9,
        168
     ]
  },
  {  
     "name":"Martinique",
     "code":"MQ",
     "call":"596",
     "latlng":[  
        14.666667,
        -61
     ]
  },
  {  
     "name":"Mauritania",
     "code":"MR",
     "call":"222",
     "latlng":[  
        20,
        -12
     ]
  },
  {  
     "name":"Mauritius",
     "code":"MU",
     "call":"230",
     "latlng":[  
        -20.28333333,
        57.55
     ]
  },
  {  
     "name":"Mayotte",
     "code":"YT",
     "call":"262",
     "latlng":[  
        -12.83333333,
        45.16666666
     ]
  },
  {  
     "name":"Mexico",
     "code":"MX",
     "call":"52",
     "latlng":[  
        23,
        -102
     ]
  },
  {  
     "name":"Micronesia, Federated States of",
     "code":"FM",
     "call":"691",
     "latlng":[  
        6.91666666,
        158.25
     ]
  },
  {  
     "name":"Moldova",
     "code":"MD",
     "call":"373",
     "latlng":[  
        47,
        29
     ]
  },
  {  
     "name":"Monaco",
     "code":"MC",
     "call":"377",
     "latlng":[  
        43.73333333,
        7.4
     ]
  },
  {  
     "name":"Mongolia",
     "code":"MN",
     "call":"976",
     "latlng":[  
        46,
        105
     ]
  },
  {  
     "name":"Montserrat",
     "code":"MS",
     "call":"1664",
     "latlng":[  
        16.75,
        -62.2
     ]
  },
  {  
     "name":"Morocco",
     "code":"MA",
     "call":"212",
     "latlng":[  
        32,
        -5
     ]
  },
  {  
     "name":"Mozambique",
     "code":"MZ",
     "call":"258",
     "latlng":[  
        -18.25,
        35
     ]
  },
  {  
     "name":"Myanmar",
     "code":"MM",
     "call":"95",
     "latlng":[  
        22,
        98
     ]
  },
  {  
     "name":"Namibia",
     "code":"NA",
     "call":"264",
     "latlng":[  
        -22,
        17
     ]
  },
  {  
     "name":"Nauru",
     "code":"NR",
     "call":"674",
     "latlng":[  
        -0.53333333,
        166.91666666
     ]
  },
  {  
     "name":"Nepal",
     "code":"NP",
     "call":"977",
     "latlng":[  
        28,
        84
     ]
  },
  {  
     "name":"Netherlands",
     "code":"NL",
     "call":"31",
     "latlng":[  
        52.5,
        5.75
     ]
  },
  {  
     "name":"Netherlands Antilles",
     "code":"AN",
     "call":"599"
  },
  {  
     "name":"New Caledonia",
     "code":"NC",
     "call":"687",
     "latlng":[  
        -21.5,
        165.5
     ]
  },
  {  
     "name":"New Zealand",
     "code":"NZ",
     "call":"64",
     "latlng":[  
        -41,
        174
     ]
  },
  {  
     "name":"Nicaragua",
     "code":"NI",
     "call":"505",
     "latlng":[  
        13,
        -85
     ]
  },
  {  
     "name":"Niger",
     "code":"NE",
     "call":"227",
     "latlng":[  
        16,
        8
     ]
  },
  {  
     "name":"Nigeria",
     "code":"NG",
     "call":"234",
     "latlng":[  
        10,
        8
     ]
  },
  {  
     "name":"Niue",
     "code":"NU",
     "call":"683",
     "latlng":[  
        -19.03333333,
        -169.86666666
     ]
  },
  {  
     "name":"Norfolk Island",
     "code":"NF",
     "call":"672",
     "latlng":[  
        -29.03333333,
        167.95
     ]
  },
  {  
     "name":"North Korea",
     "code":"KP",
     "call":"850",
     "latlng":[  
        40,
        127
     ]
  },
  {  
     "name":"Northern Ireland",
     "code":"GB",
     "call":"353",
     "latlng":[  
        54,
        -2
     ]
  },
  {  
     "name":"Northern Mariana Islands",
     "code":"MP",
     "call":"1670",
     "latlng":[  
        15.2,
        145.75
     ]
  },
  {  
     "name":"Norway",
     "code":"NO",
     "call":"47",
     "latlng":[  
        62,
        10
     ]
  },
  {  
     "name":"Oman",
     "code":"OM",
     "call":"968",
     "latlng":[  
        21,
        57
     ]
  },
  {  
     "name":"Pakistan",
     "code":"PK",
     "call":"92",
     "latlng":[  
        30,
        70
     ]
  },
  {  
     "name":"Palau",
     "code":"PW",
     "call":"680",
     "latlng":[  
        7.5,
        134.5
     ]
  },
  {  
     "name":"Palestine",
     "code":"PS",
     "call":"970",
     "latlng":[  
        31.9,
        35.2
     ]
  },
  {  
     "name":"Panama",
     "code":"PA",
     "call":"507",
     "latlng":[  
        9,
        -80
     ]
  },
  {  
     "name":"Papua New Guinea",
     "code":"PG",
     "call":"675",
     "latlng":[  
        -6,
        147
     ]
  },
  {  
     "name":"Paraguay",
     "code":"PY",
     "call":"595",
     "latlng":[  
        -23,
        -58
     ]
  },
  {  
     "name":"Peru",
     "code":"PE",
     "call":"51",
     "latlng":[  
        -10,
        -76
     ]
  },
  {  
     "name":"Philippines",
     "code":"PH",
     "call":"63",
     "latlng":[  
        13,
        122
     ]
  },
  {  
     "name":"Pitcairn",
     "code":"PN",
     "call":"64",
     "latlng":[  
        -25.06666666,
        -130.1
     ]
  },
  {  
     "name":"Poland",
     "code":"PL",
     "call":"48",
     "latlng":[  
        52,
        20
     ]
  },
  {  
     "name":"Portugal",
     "code":"PT",
     "call":"351",
     "latlng":[  
        39.5,
        -8
     ]
  },
  {  
     "name":"Puerto Rico",
     "code":"PR",
     "call":"1939",
     "latlng":[  
        18.25,
        -66.5
     ]
  },
  {  
     "name":"Qatar",
     "code":"QA",
     "call":"974",
     "latlng":[  
        25.5,
        51.25
     ]
  },
  {  
     "name":"Reunion",
     "code":"RE",
     "call":"262",
     "latlng":[  
        -21.15,
        55.5
     ]
  },
  {  
     "name":"Romania",
     "code":"RO",
     "call":"40",
     "latlng":[  
        46,
        25
     ]
  },
  {  
     "name":"Russian Federation",
     "code":"RU",
     "call":"7",
     "latlng":[  
        60,
        100
     ]
  },
  {  
     "name":"Rwanda",
     "code":"RW",
     "call":"250",
     "latlng":[  
        -2,
        30
     ]
  },
  {  
     "name":"Saint Helena",
     "code":"SH",
     "call":"290"
  },
  {  
     "name":"Saint Kitts and Nevis",
     "code":"KN",
     "call":"1869",
     "latlng":[  
        17.33333333,
        -62.75
     ]
  },
  {  
     "name":"Saint Lucia",
     "code":"LC",
     "call":"1758",
     "latlng":[  
        13.88333333,
        -60.96666666
     ]
  },
  {  
     "name":"Saint Pierre and Miquelon",
     "code":"PM",
     "call":"508",
     "latlng":[  
        46.83333333,
        -56.33333333
     ]
  },
  {  
     "name":"Saint Vincent and the Grenadines",
     "code":"VC",
     "call":"1784",
     "latlng":[  
        13.25,
        -61.2
     ]
  },
  {  
     "name":"Samoa",
     "code":"WS",
     "call":"685",
     "latlng":[  
        -13.58333333,
        -172.33333333
     ]
  },
  {  
     "name":"San Marino",
     "code":"SM",
     "call":"378",
     "latlng":[  
        43.76666666,
        12.41666666
     ]
  },
  {  
     "name":"Sao Tome and Principe",
     "code":"ST",
     "call":"239",
     "latlng":[  
        1,
        7
     ]
  },
  {  
     "name":"Saudi Arabia",
     "code":"SA",
     "call":"966",
     "latlng":[  
        25,
        45
     ]
  },
  {  
     "name":"Senegal",
     "code":"SN",
     "call":"221",
     "latlng":[  
        14,
        -14
     ]
  },
  {  
     "name":"Seychelles",
     "code":"SC",
     "call":"248",
     "latlng":[  
        -4.58333333,
        55.66666666
     ]
  },
  {  
     "name":"Sierra Leone",
     "code":"SL",
     "call":"232",
     "latlng":[  
        8.5,
        -11.5
     ]
  },
  {  
     "name":"Singapore",
     "code":"SG",
     "call":"65",
     "latlng":[  
        1.36666666,
        103.8
     ]
  },
  {  
     "name":"Slovakia",
     "code":"SK",
     "call":"421",
     "latlng":[  
        48.66666666,
        19.5
     ]
  },
  {  
     "name":"Slovenia",
     "code":"SI",
     "call":"386",
     "latlng":[  
        46.11666666,
        14.81666666
     ]
  },
  {  
     "name":"Solomon Islands",
     "code":"SB",
     "call":"677",
     "latlng":[  
        -8,
        159
     ]
  },
  {  
     "name":"Somalia",
     "code":"SO",
     "call":"252",
     "latlng":[  
        10,
        49
     ]
  },
  {  
     "name":"South Africa",
     "code":"ZA",
     "call":"27",
     "latlng":[  
        -29,
        24
     ]
  },
  {  
     "name":"South Georgia and the South Sandwich Islands",
     "code":"GS",
     "call":"500",
     "latlng":[  
        -54.5,
        -37
     ]
  },
  {  
     "name":"South Korea",
     "code":"KR",
     "call":"82",
     "latlng":[  
        37,
        127.5
     ]
  },
  {  
     "name":"South Sudan",
     "code":"SS",
     "call":"211",
     "latlng":[  
        7,
        30
     ]
  },
  {  
     "name":"Spain",
     "code":"ES",
     "call":"34",
     "latlng":[  
        40,
        -4
     ]
  },
  {  
     "name":"Sri Lanka",
     "code":"LK",
     "latlng":[  
        7,
        81
     ]
  },
  {  
     "name":"Sudan",
     "code":"SD",
     "call":"249",
     "latlng":[  
        15,
        30
     ]
  },
  {  
     "name":"Suriname",
     "code":"SR",
     "call":"597",
     "latlng":[  
        4,
        -56
     ]
  },
  {  
     "name":"Svalbard and Jan Mayen",
     "code":"SJ",
     "call":"47",
     "latlng":[  
        78,
        20
     ]
  },
  {  
     "name":"Swaziland",
     "code":"SZ",
     "call":"268",
     "latlng":[  
        -26.5,
        31.5
     ]
  },
  {  
     "name":"Sweden",
     "code":"SE",
     "call":"46",
     "latlng":[  
        62,
        15
     ]
  },
  {  
     "name":"Switzerland",
     "code":"CH",
     "call":"41",
     "latlng":[  
        47,
        8
     ]
  },
  {  
     "name":"Syria",
     "code":"SY",
     "call":"963",
     "latlng":[  
        35,
        38
     ]
  },
  {  
     "name":"Tajikistan",
     "code":"TJ",
     "call":"992",
     "latlng":[  
        39,
        71
     ]
  },
  {  
     "name":"Tanzania",
     "code":"TZ",
     "call":"255",
     "latlng":[  
        -6,
        35
     ]
  },
  {  
     "name":"Thailand",
     "code":"TH",
     "call":"66",
     "latlng":[  
        15,
        100
     ]
  },
  {  
     "name":"The Democratic Republic of Congo",
     "code":"CD",
     "call":"243",
     "latlng":[  
        0,
        25
     ]
  },
  {  
     "name":"Togo",
     "code":"TG",
     "call":"228",
     "latlng":[  
        8,
        1.16666666
     ]
  },
  {  
     "name":"Tokelau",
     "code":"TK",
     "call":"690",
     "latlng":[  
        -9,
        -172
     ]
  },
  {  
     "name":"Tonga",
     "code":"TO",
     "call":"676",
     "latlng":[  
        -20,
        -175
     ]
  },
  {  
     "name":"Trinidad and Tobago",
     "code":"TT",
     "call":"1868",
     "latlng":[  
        11,
        -61
     ]
  },
  {  
     "name":"Tunisia",
     "code":"TN",
     "call":"216",
     "latlng":[  
        34,
        9
     ]
  },
  {  
     "name":"Turkey",
     "code":"TR",
     "call":"90",
     "latlng":[  
        39,
        35
     ]
  },
  {  
     "name":"Turkmenistan",
     "code":"TM",
     "call":"993",
     "latlng":[  
        40,
        60
     ]
  },
  {  
     "name":"Turks and Caicos Islands",
     "code":"TC",
     "call":"1649",
     "latlng":[  
        21.75,
        -71.58333333
     ]
  },
  {  
     "name":"Tuvalu",
     "code":"TV",
     "call":"688",
     "latlng":[  
        -8,
        178
     ]
  },
  {  
     "name":"Uganda",
     "code":"UG",
     "call":"256",
     "latlng":[  
        1,
        32
     ]
  },
  {  
     "name":"Ukraine",
     "code":"UA",
     "call":"380",
     "latlng":[  
        49,
        32
     ]
  },
  {  
     "name":"United Arab Emirates",
     "code":"AE",
     "call":"971",
     "latlng":[  
        24,
        54
     ]
  },
  {  
     "name":"United Kingdom",
     "code":"GB",
     "call":"44",
     "latlng":[  
        54,
        -2
     ]
  },
  {  
     "name":"United States",
     "code":"US",
     "call":"1",
     "latlng":[  
        38,
        -97
     ]
  },
  {  
     "name":"United States Minor Outlying Islands",
     "code":"UM",
     "call":"1",
     "latlng":[  
        19.2911437,
        166.618332
     ]
  },
  {  
     "name":"Uruguay",
     "code":"UY",
     "call":"598",
     "latlng":[  
        -33,
        -56
     ]
  },
  {  
     "name":"Uzbekistan",
     "code":"UZ",
     "call":"998",
     "latlng":[  
        41,
        64
     ]
  },
  {  
     "name":"Vanuatu",
     "code":"VU",
     "call":"678",
     "latlng":[  
        -16,
        167
     ]
  },
  {  
     "name":"Venezuela",
     "code":"VE",
     "call":"58",
     "latlng":[  
        8,
        -66
     ]
  },
  {  
     "name":"Vietnam",
     "code":"VN",
     "call":"84",
     "latlng":[  
        16.16666666,
        107.83333333
     ]
  },
  {  
     "name":"Virgin Islands, British",
     "code":"VG",
     "call":"1",
     "latlng":[  
        18.431383,
        -64.62305
     ]
  },
  {  
     "name":"Virgin Islands, U.S.",
     "code":"VI",
     "call":"1",
     "latlng":[  
        18.35,
        -64.933333
     ]
  },
  {  
     "name":"Wallis and Futuna",
     "code":"WF",
     "call":"681",
     "latlng":[  
        -13.3,
        -176.2
     ]
  },
  {  
     "name":"Western Sahara",
     "code":"EH",
     "call":"212",
     "latlng":[  
        24.5,
        -13
     ]
  },
  {  
     "name":"Yemen",
     "code":"YE",
     "call":"967",
     "latlng":[  
        15,
        48
     ]
  },
  {  
     "name":"Zambia",
     "code":"ZM",
     "call":"260",
     "latlng":[  
        -15,
        30
     ]
  },
  {  
     "name":"Zimbabwe",
     "code":"ZW",
     "call":"263",
     "latlng":[  
        -20,
        30
     ]
  }
];