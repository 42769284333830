import { Pipe, PipeTransform } from '@angular/core';
import { ScienceCode } from '../data';

@Pipe({
  name: 'scienceCode'
})
export class ScienceCodePipe implements PipeTransform {

  transform(value: any): any {
    let valeur = value;
    for (const inf of ScienceCode) {
      if ( inf.code === value ) { valeur = inf.name; }
    }
    return valeur;
  }

}
