import { Injectable } from '@angular/core';
import { NavController, AlertController, ModalController } from '@ionic/angular';

import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';

import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})




export class ModelService {

	additifLISTESPrototype = {
		"CODE": "",
	  };
	  
	  additifLISTESAJOUTPrototype = {
		"CODE": "",
		"NOM": "",
		"TXT": "",
	  };

	additifMEDIASPrototype = {
					  "ALLERGIEPOSSIBLE": "",
					  "ALLERGIES": false,
					  "CODE": "",
					  "DIAG": "",
					  "DIAG_TXT": "",
					  "DIGESTION": "",
					  "DJA": "",
					  "INTERDICTIONS": "",
					  "REMARQUES": "",
					  "RISQUES": ""
					};

	additifSCIENCEPrototype = {
					    "DIAG": "",
					    "DJA_EFSA": "",
					    "ETUDES": [],
					    "ETUDES_SECU": [],
					    "INFOS": [
					    			{
					    				"CODE": "Code...",
					    				"SOURCES": [],
					    				"description": "Description...",
					    			}
					    		]


	};
	additifREGLEMENTATIONPrototype = {};
	additifFORMULATIONPrototype = {
		"clean_label": "",
		"clean_label_txt": "",
		"INFOS": [
			{
				"CODE": "Code...",
				"SOURCES": [],
				"description": "Description...",
			}
		]
	};

	additifPrototype = {
		statut:false,
		additifID:'',
		additif:'',
		categorie:'',
		MEDIA:this.additifMEDIASPrototype,
		SCIENCE: this.additifSCIENCEPrototype,
		REGLEMENTATION : this.additifREGLEMENTATIONPrototype,
		FORMULATION : this.additifFORMULATIONPrototype,

	}

  constructor(
  				public alertController: AlertController,
  				public db: AngularFirestore,
			    public globalService: GlobalService,
			    public navCtrl: NavController,
  			) {

  }

  	async creationDoc(
					    collection: string = 'additifs',
					    nameID: string = 'postID',
					    titre: string = 'New Doc'
  			  		) {

  		let doc = this.additifPrototype;

  		doc.additifID = nameID;

	    let INPUTS = [];
	    INPUTS.push({
	      name: 'additifID',
	      type: 'text',
	      placeholder: 'E...'
	    });

	    const alert = await this.alertController.create({
	      header: 'Nouvel additif',
	      inputs: INPUTS,
	      buttons: [
	        {
	          text: 'Annuler',
	          role: 'cancel',
	          cssClass: 'secondary',
	          handler: () => {
	            console.log('Confirm Cancel');
	          }
	        }, {
	          text: 'Ok',
	          handler: (data) => {
	            console.log('Confirm Ok', data);
	           
	           	doc.additifID = data.additifID;

	            this.db.collection(collection).doc(doc.additifID).set(doc).then(() => {
	              	console.log('creationDoc OK');
		            let redirect;
                	redirect = this.globalService.urlAdditif(doc.additifID)
                	this.navCtrl.navigateForward(redirect);

	            }).catch(error => {
	              console.log('creationDoc KO', error);
	            });
	          }
	        }
	      ]
	    });
	    await alert.present();

  	}

  	async deleteDoc( collection: string = 'additifs', postID, reroute = false ) {

		console.log(postID);

	    const alert = await this.alertController.create({
	      header: 'Suppression',
	      message: 'Toute suppression est définitive ! L\'historique, le cas échéant, sera également supprimé. En mode édition, vous pouvez définir un élément en tant que brouillon si vous souhaitez le désactiver temporairement',
	      buttons: [
	        {
	          text: 'Annuler',
	          role: 'cancel',
	          cssClass: 'secondary',
	          handler: () => {
	            console.log('Confirm Cancel');
	          }
	        }, {
	          text: 'Je confirme la suppression définitive',
	          handler: (data) => {
	          	this.db.collection(collection).doc(postID).delete();
				this.globalService.toast('Suppression OK');
	          	if(reroute) {
	          		this.navCtrl.navigateForward('/');
	          	}

	          }
	        }
	      ]
	    });
	    await alert.present();

  		
  	}

}
