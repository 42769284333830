import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-cgu',
  templateUrl: './cgu.page.html',
  styleUrls: ['./cgu.page.scss'],
})
export class CguPage implements OnInit {

  // variables du composant
  public modal = false;
  public titre = 'Conditions générales d\'utilisation';
  public couleurdeFond = 'fond';

  breadcrum = [
    {url:'/', name:'Accueil'},
    {url:'/cgu', name:'Conditions Générales d\'Utilisation'},
  ]

  constructor(
    public modalCtrl: ModalController,
    private title: Title,
    private meta: Meta,
  ) {
    // DEBUT SEO  *************************************
    // SEO TITLE
    this.title.setTitle('Conditions générales d\'utilisation - AdditiveScreener');
    // SEO TAGS
    this.meta.updateTag({ name: 'robots', content: 'index' });
    this.meta.updateTag({ name: 'description', content: '' });
    this.meta.updateTag({ name: 'canonical', content: 'https://additivescreener.foodinnov.fr/cgu' });
    // FIN SEO ****************************************
   }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
  }

}
