import { userModel } from './user.service';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingController, AlertController, ToastController, Platform, NavController } from '@ionic/angular';
// import { Secteurs } from '../data';

@Injectable({
  providedIn: 'root'
})
export class AuthentificationService {

  public user: userModel = {};
  public isConnected: boolean = null;
  public authSubject = new Subject();
  public authWindow: Window = null;
  private userSubscribe: Subscription = null;

  constructor(
    public db: AngularFirestore,
    public router: Router,
    public loadingController: LoadingController,
    public alertCtrl: AlertController,
    public toastCtrl: ToastController,
    public platform: Platform,
    public navCtrl: NavController,
    public toastController: ToastController,
  ) {

  }

  async userSignIn(user) {
    return firebase.auth().signInWithEmailAndPassword(user.email, user.motdepasse);
  }

  async userLogOut() {
    this.user = {};
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
      this.userSubscribe = null;
    }
    return firebase.auth().signOut();
  }

  userRegister(user, motdepasse) {
    return firebase.auth().createUserWithEmailAndPassword(user.email, motdepasse);
  }

  async updatePassword(password: { old: string, new: string }, mail: string) {
    let credential = await firebase.auth.EmailAuthProvider.credential(mail, password.old);

    return firebase.auth().currentUser.reauthenticateWithCredential(credential)
    .then(() => {
      firebase.auth().currentUser.updatePassword(password.new);
    })
    .catch((err) => {
      switch(err.code) {
        case 'auth/invalid-credential':
          this.errorAlert('Mauvais mot de passe', 'Le mot de passe entré ne correpond pas à votre mot de passe', null);
        default:
          this.errorAlert('Erreur', 'Un problème est survenu, code erreur: ' + err.code, null);
      }
    });
    
  }

  userHandling() {
    //console.log('userHandling()');
    firebase.auth().onAuthStateChanged(user => {
      if (this.authWindow != null)
        this.authWindow.close();
      if (user) {
        //console.log('userHandling()=>Connected');
        this.userSubscribe = this.db.collection('users').doc(user.uid).valueChanges().subscribe((data) => {
          if (data['enabled'] == false) {
            this.alertCtrl.create({
              mode: 'ios',
              header: 'Erreur lors de la connexion',
              message: 'Votre compte a été désactivé.'
            }).then(alertElem => alertElem.present());
            this.userSubscribe.unsubscribe();
            this.userSubscribe = null;
            this.userLogOut();
            return;
          }
          //console.log('userHandling()=>Connected=>data', data);
          this.isConnected = true;
          this.authSubject.next(data);
        });
        if (user.uid.split(':')[0] == 'CCI')
          this.navCtrl.navigateRoot('/');
      } else {
        //console.log('userHandling()=>Not connected');
        this.isConnected = false;
        this.authSubject.next(null);
      }
    });
  }

  async errorAlert(title: string, reason: string, handler) {
    const alert = await this.alertCtrl.create({
      header: title,
      message: reason,
      buttons: [{
        text: 'D\'accord',
        role: 'close',
        handler
      }]
    });
    await alert.present();
  }
  
  waitForUser() {
    return new Promise<boolean>(async (resolve) => {
      if (this.isConnected === true) { resolve(true); }
      if (this.isConnected === false) {
        this.errorAlert('Vous n\'êtes pas connecté', 'Veuillez-vous connecter afin d\'accéder à cette page', () => {this.router.navigate(['/connexion']); });
        resolve(false);
      }
      if (this.isConnected === null) {
        const temp = await this.authSubject.subscribe(async () => {
          if (this.isConnected === false) {
            this.errorAlert('Vous n\'êtes pas connecté', 'Veuillez-vous connecter afin d\'accéder à cette page', () => {this.router.navigate(['/connexion']); });
            resolve(false);
          }
          await temp.unsubscribe();
          resolve(true);
        });
      }
    });
  }

  // PASSE PERDU /////////
  async forgotPasswordAlert() {

		const PASSE_OUBLIE = 'Mot de passe oublié'; // this.translate.instant('CONNECT.PASSE_OUBLIE');
		const PASSE_EMAIL = 'Entrez votre adresse e-mail'; // this.translate.instant('CONNECT.PASSE_EMAIL');
		const EMAIL =  'Votre E-mail'; // this.translate.instant('CONNECT.EMAIL');
		const ANNULER = 'Annuler'; //this.translate.instant('BT.ANNULER');
		const VALIDER = 'Ok'; //this.translate.instant('BT.OK');

		const alert = await this.alertCtrl.create({
		  header: PASSE_OUBLIE,
		  message: PASSE_EMAIL,
		  inputs: [
			{
			  type: 'email',
			  placeholder: EMAIL
			},
		  ],
		  buttons: [
			{
			  text: ANNULER,
			  role: 'close'
			},
			{
			  text: VALIDER,
			  handler: (value) => {
				const mail = value[0];
				if(this.validateEmail(mail)) {
				  this.forgotPassword(mail);
				}
				else {
          console.log('erreur de saisie');
          this.toast('E-mail non valide !',false); 
				  return false;
				}
	
			  }
			}
		  ]
		});
		await alert.present();
	  }

	forgotPassword(email) {
		return firebase.auth().sendPasswordResetEmail(email).then((data)=>{
      console.log('ok', data);
     this.toast('Un E-mail vous a été envoyé pour changer votre mot de passe.',true, 5000); 
      // if(data['code'] == 'auth/user-not-found'){ this.toast(data['message']); }
    }).catch((error) => { 
      console.log('ko' , error);
      this.toast(error['message'],false); 
  })
  }
  
  validateEmail(email) {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
  }
  
  async toast(str,ok=true,duration = 2000) {
    console.log('toast');
    const toast = await this.toastController.create({
      color : ok? 'primary': 'danger',
      message: str,
      duration,
      position: 'middle',
    });
    toast.present();
  }
}
