import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-couleursmedia',
  templateUrl: './couleursmedia.component.html',
  styleUrls: ['./couleursmedia.component.scss'],
})
export class CouleursmediaComponent implements OnInit {

  constructor(
    public popoverCtrl: PopoverController,
    public globalService: GlobalService,
  ) { }

  ngOnInit() {}

}
