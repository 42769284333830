import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-formerror',
  templateUrl: './formerror.component.html',
  styleUrls: ['./formerror.component.scss'],
})
export class FormerrorComponent implements OnInit {


  @Input() errorMessages: any;
  @Input() variable: any;

  constructor() { 

  }

  ngOnInit() {
    //console.log(this.errorMessages);
    //console.log(this.variable);
  }

}
