// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDDrYtgsl0ISk9rvZKo-JThI8YVUUNJLZo",
    authDomain: "additivescreener.firebaseapp.com",
    databaseURL: "https://additivescreener.firebaseio.com",
    projectId: "additivescreener",
    storageBucket: "additivescreener.appspot.com",
    messagingSenderId: "66093477514",
    appId: "1:66093477514:web:47b46c0827647fc8410773",
    measurementId: "G-WMJFTB1BQG"
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoiZnJhbmNvZmlsIiwiYSI6ImNqeXB1czM1eDAxYmczYnJ2anE4MXZidjQifQ.VgmafrtoHr9pShkkOmfYAg',
    style : 'mapbox://styles/additivescreener/cjypv1yut5tsu1dmcowtkdlty'
  },
  avatar : './assets/images/noprofile.png',
  linkPreviewURL: 'http://api.johndoe-et-fils.com/preview/?url=',
  elasticsearchURL: 'https://chercher.top/additivescreener',
};